import { Flex, Typography } from "antd";

type FormulaSuggestionOptionTitleProps = {
    title?: string;
    children?: React.ReactNode;
};

export default function FormulaSuggestionOptionTitle({
    title,
    children,
}: FormulaSuggestionOptionTitleProps) {
    return (
        <Flex
            align="center"
            justify="space-between"
            style={{ padding: "10px 0" }}
        >
            <Typography style={{ fontWeight: "bold" }}>{title}</Typography>
            {children}
        </Flex>
    );
}
