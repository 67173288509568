export type IDataPreviewDataType = Record<string, React.ReactNode>;

type IDataPreviewTableProps = {
    headers: IDataPreviewDataType;
    rows: IDataPreviewDataType[];
    children?: React.ReactNode;
    footer?: React.ReactNode;
};

export default function DataPreviewTable({
    children,
    headers,
    rows,
    footer,
}: IDataPreviewTableProps) {
    return (
        <table className="relative">
            <thead>
                <tr>
                    {Object.keys(headers).map((col) => (
                        <th
                            key={`table-header-${col}`}
                            className="sticky top-0 bg-slate-100 whitespace-nowrap text-left text-sm align-top py-2 px-4"
                        >
                            {headers[col]}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((record, rowIndex) => (
                    <tr className="border-b border-slate-100 hover:bg-slate-100">
                        {Object.keys(record).map((colIndex) => (
                            <td
                                key={`${rowIndex}-${colIndex}`}
                                className="text-left text-sm align-top py-2 px-4"
                            >
                                {record[colIndex]}
                            </td>
                        ))}
                    </tr>
                ))}
                {children}
            </tbody>
            <tfoot>{footer}</tfoot>
        </table>
    );
}
