import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Space, InputNumber, Radio, Select, Table, AutoComplete, Checkbox, Typography } from 'antd';
import { FullHeightContainerLayout, CopyInput } from "components";
import { diff } from 'deep-object-diff';
import _ from 'lodash';
import { nameRules, descriptionRules } from 'common/FormValidationRules';

class ManageSystemConnectionAwsS3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            connectionDefault: {
                name: '',
                description: '',
                accessKeyId: '',
                secretAccessKey: ''
            },
            readOnlyView: this.props.action === "view"
        };
        this.formRef = React.createRef();
        this.emptyObject = {};
    }

    componentDidMount() {
        if (this.props.connection) {
            this.formRef.current.setFieldsValue({ ...this.props.connection });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.connection && prevProps.connection !== this.props.connection) {
            this.formRef.current.setFieldsValue({ ...this.props.connection });
        }
    }

    getFormErrors = fieldName => {
        if (this.props.formErrors && this.props.formErrors[fieldName]) {
            return {
                help: this.props.formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    onFormSubmit = (formData) => {
        let connection = { ...formData };
        if (this.props.action === "create") {
            connection.active = true;
            this.props.onSave(connection);
        }
        else {
            let diffObject = diff(this.props.connection, connection);
            let updatedObject = {};
            for (let key in diffObject) {
                if (diffObject[key] !== undefined) {
                    updatedObject[key] = connection[key];
                }
            }
            if (_.isEmpty(updatedObject) === false) {
                updatedObject.version = this.props.connection.version;
            }

            this.props.onSave(this.props.connection.connectionId, updatedObject);
        }
    }

    isReadOnlyView = () => {
        return (this.state.readOnlyView || !(this.props.permission.canEdit || this.props.permission.canAdd));
    }

    isSaveAllowed = () => {
        if (this.props.action === "edit") {
            return this.props.permission.canEdit;
        }
        else if (this.props.action === "create") {
            return this.props.permission.canAdd;
        }
        return false;
    }

    render() {
        return (
            <Form
                layout="horizontal"
                labelAlign="right"
                labelCol={{ md: { span: 24 }, lg: { span: 6 }, xl: { span: 5 }, xxl: { span: 3 } }}
                ref={this.formRef}
                name="basic"
                onFinish={this.onFormSubmit}
                initialValues={this.state.connectionDefault}
                onValuesChange={this.props.onValuesChanged}
                style={{ height: "100%" }}>
                <FullHeightContainerLayout
                    showFooter={true}
                    content={
                        <>
                            <Form.Item
                                labelAlign="right"
                                className="wrap-label"
                                {...this.getFormErrors("name")}
                                label="Connection Name"
                                name={["name"]}
                                rules={nameRules}
                                hasFeedback>
                                <Input placeholder="Connection Name" readOnly={this.isReadOnlyView()} />
                            </Form.Item>
                            <Form.Item
                                labelAlign="right"
                                className="wrap-label"
                                {...this.getFormErrors("description")}
                                label="Description"
                                name={["description"]}
                                rules={[{ required: true, message: `Description is required` }]}
                                // required
                                hasFeedback>
                                <Input placeholder="Description" readOnly={this.isReadOnlyView()} />
                            </Form.Item>
                            <Form.Item
                                labelAlign="right"
                                className="wrap-label"
                                {...this.getFormErrors("accessKeyId")}
                                label="Access key ID"
                                name={["accessKeyId"]}
                                rules={[{ required: true, message: `Access key ID is required` }]}
                                hasFeedback>
                                <Input placeholder="Access key ID" readOnly={this.isReadOnlyView()} />
                            </Form.Item>
                            <Form.Item
                                labelAlign="right"
                                className="wrap-label"
                                {...this.getFormErrors("secretAccessKey")}
                                label="Secret Access Key"
                                name={["secretAccessKey"]}
                                rules={this.props.action === "create" ? [{ required: true, message: `Secret Access Key is required` }] : []}
                                hasFeedback>
                                <Input placeholder={this.props.action === "create" ? "Secret Access Key" : "***************************"} readOnly={this.isReadOnlyView()} />
                            </Form.Item>
                            <Form.Item
                                labelAlign="right"
                                className="wrap-label"
                                {...this.getFormErrors("bucket")}
                                label="Bucket"
                                name={["bucket"]}
                                rules={descriptionRules}
                                hasFeedback>
                                <Input placeholder="S3 Bucket" readOnly={this.isReadOnlyView()} />
                            </Form.Item>
                        </>
                    }
                    footer={
                        <Row>
                            <Col span={24} className="footer-right-column">
                                <Space>
                                    <Button onClick={this.props.onCancel}>Cancel</Button>
                                    <Button type="primary" htmlType="submit" disabled={this.isReadOnlyView() || !this.isSaveAllowed()}>Save</Button>
                                </Space>
                            </Col>
                        </Row>
                    }>
                </FullHeightContainerLayout>
            </Form>
        );
    }
}

export default ManageSystemConnectionAwsS3