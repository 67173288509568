import {
    Edge,
    Handle,
    MarkerType,
    Node,
    NodeProps,
    Position,
    useHandleConnections,
    useNodesData,
    useReactFlow,
    useUpdateNodeInternals,
} from "@xyflow/react";
import React, { memo } from "react";
import { DataMappedNodeType } from "./DataMappedNode";
import { SourceNodeType } from "./SourceNode";

type TargetNodeType = Node<
    {
        label: string;
        dataType?: string;
    },
    "targetNode"
>;

function TargetNode({
    id,
    data,
    isConnectable,
    targetPosition,
    positionAbsoluteX,
    // positionAbsoluteY,
}: NodeProps<TargetNodeType>) {
    const { addEdges, addNodes, setNodes, getNodes } = useReactFlow();
    const updateNodeInternals = useUpdateNodeInternals();
    const targetConnections = useHandleConnections({
        type: "target",
        id: "handle-target",
    });

    // Subscribe to changes to all Source Nodes
    const connectedSourceIds = React.useMemo((): string[] => {
        const _ids: string[] = [];
        targetConnections &&
            targetConnections.forEach((c) => _ids.push(c.source));
        return _ids;
    }, [targetConnections]);
    const sourceNodes = useNodesData<SourceNodeType>(connectedSourceIds);

    const addChildNodes = React.useCallback(() => {
        if (sourceNodes && sourceNodes.length > 0) {
            const sourceIds: string[] = [];
            const dataLabels: string[] = [];
            const sourceColumns: string[] = [];

            sourceNodes.forEach((sourceNode) => {
                sourceIds.push(sourceNode.id);
                dataLabels.push(sourceNode.data.label);
                sourceColumns.push(sourceNode.data.column);
            });

            const _node: DataMappedNodeType = {
                id: `${id}-${sourceIds.join("-")}`,
                type: "dataMappedNode",
                parentId: id,
                targetPosition: Position.Left,
                position: {
                    x: positionAbsoluteX - 300,
                    y: 0,
                },
                data: {
                    dataType: sourceIds.length > 1 ? "FORMULA" : "DATA_COLUMN",
                    label: `${dataLabels.join("-")}-${data.label}`,
                    sourceColumns: sourceColumns,
                    sourceIds: sourceIds,
                    targetIds: [id],
                },
            };
            const _edge: Edge = {
                id: `target-child-${id}-${sourceIds.join("-")}`,
                source: id,
                target: `${id}-${sourceIds.join("-")}`,
                deletable: false,
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 20,
                    height: 20,
                    color: "#b1b1b7",
                },
            };
            addNodes(_node);
            addEdges(_edge);

            updateNodeInternals(id); // Update handle
        }
    }, [
        sourceNodes,
        id,
        positionAbsoluteX,
        // positionAbsoluteY,
        data.label,
        addNodes,
        addEdges,
        updateNodeInternals,
    ]);

    // Wait for Source connection and add DataType nodes
    React.useEffect(() => {
        // Change in connections, remove any existing child nodes first
        const nodes = getNodes();
        targetConnections.forEach((edge) => {
            setNodes(nodes.filter((n) => n.parentId !== edge.target));
        });
        // Then add new child
        addChildNodes();
    }, [addChildNodes, getNodes, setNodes, targetConnections]);

    return (
        <>
            {isConnectable && targetPosition && (
                <Handle
                    id="handle-target"
                    type="target"
                    position={targetPosition}
                    style={handleStyle}
                    className="bg-emerald-600"
                />
            )}
            <div className="flex flex-col justify-center items-center gap-1 p-3 bg-emerald-100 rounded-lg rounded-tr-none min-w-40 min-h-16">
                <span className="text-sm">{data.label}</span>
                {data.dataType && (
                    <div className="absolute -top-2 right-0 text-[0.6em] bg-emerald-200 px-2 py-[0.3em] rounded-bl-lg rounded-tr-lg rounded-tl-sm">
                        {data.dataType}
                    </div>
                )}
            </div>
            {isConnectable && sourceNodes?.length > 0 && (
                <Handle
                    id="handle-source"
                    type="source"
                    position={Position.Right}
                    isConnectable={false}
                    style={handleStyle}
                    className="bg-emerald-600"
                />
            )}
        </>
    );
}

const handleStyle: React.CSSProperties = {
    backgroundColor: "#059669",
    height: "0.8rem",
    width: "0.8rem",
};

export default memo(TargetNode);
