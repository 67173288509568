import React, { useState, useEffect } from "react";
import { Space, Table, Typography, Tooltip, Tag, Row, Col } from "antd";
import { PlusOutlined, WarningTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { FormDisplayField, SchemaFieldTitle, DataObjectLink } from 'components';

const { Title, Text } = Typography;
const { Column } = Table;

const HighlightValue = ({ highlightValues }) => {
    return highlightValues.map((value, index) => {
        let hightlightKey = String(Object.keys(value)[0]);
        let hightlightValue = String(Object.values(value)[0]);
        if (hightlightValue && hightlightValue.trim()) {
            let hightlightComponents = [];
            let text = "";
            for (let i = 0; i < hightlightValue.length; i++) {
                if (hightlightValue.substring(i, i + 4) === "<em>") {
                    if (text) {
                        hightlightComponents.push(<Text>{text}</Text>);
                    }
                    text = "";
                    i = (i + 4) - 1;
                }
                else if (hightlightValue.substring(i, i + 5) === "</em>") {
                    if (text) {
                        hightlightComponents.push(<Text mark>{text}</Text>);
                    }
                    text = "";
                    i = (i + 5) - 1;
                }
                else {
                    text = text + hightlightValue[i];
                }
            }
            if (text) {
                hightlightComponents.push(<Text>{text}</Text>);
            }
            if (hightlightComponents.length > 0) {
                return <Row key={`${hightlightKey}_${index}`}>
                    <Col><><Text strong underline>{hightlightKey}</Text><Text strong>{":"}&nbsp;</Text></></Col>
                    <Col>
                        {hightlightComponents.map((hl, i) => (
                            <React.Fragment key={`${hightlightKey}-${index}-${i}`}>{hl}</React.Fragment>
                        ))}
                    </Col>
                </Row>
            }
        }
        return <Space key={`${hightlightKey}_${index}`}><Text strong underline>{hightlightKey}</Text><Text strong>{": "}</Text><Text>{hightlightValue}</Text></Space>
    })
}

/** @type {import('./types').DataObjectSearchResultList} */
const DataObjectSearchResultList = ({
    loading,
    hits,
    totalHits = 0,
    tableHeight,
    tableClasses = [],
    onDataObjectSelected,
    onPageSelect
}) => {
    const [highlightColumns, setHighlightColumns] = useState({
        tagColumns: [],
        indexColumnCount: 0
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tableScroll, setTableScroll] = useState({ y: `${tableHeight}vh` });

    useEffect(() => {
        let tagColumns = new Set();
        let indexColumnCount = 0;
        if (hits && hits.length > 0) {
            for (let hit of hits) {
                if (hit.highlight) {
                    let hitKeys = Object.keys(hit.highlight);
                    let nonTagColumnCount = 0;
                    for (let i = 0; i < hitKeys.length; i++) {
                        if (hit.highlight[hitKeys[i]].tags && hit.highlight[hitKeys[i]].tags.length > 0) {
                            for (let tag of hit.highlight[hitKeys[i]].tags) {
                                tagColumns.add(tag);
                                if (!hit.highlight[tag]) {
                                    hit.highlight[tag] = [];
                                }
                                hit.highlight[tag].push({
                                    [hitKeys[i]]: hit.highlight[hitKeys[i]].values
                                });
                            }
                        }
                        else {
                            hit.highlight[nonTagColumnCount] = [{
                                [hitKeys[i]]: hit.highlight[hitKeys[i]].values
                            }];
                            nonTagColumnCount = nonTagColumnCount + 1;
                        }
                        delete hit.highlight[hitKeys[i]];
                    }

                    indexColumnCount = Math.max(indexColumnCount, nonTagColumnCount);
                }
            }
        }
        setHighlightColumns({
            tagColumns: Array.from(tagColumns),
            indexColumnCount
        });
        let currentTableScroll = { ...tableScroll };
        currentTableScroll.x = 200 * ((Array.from(tagColumns).length + indexColumnCount) + 4);
        setTableScroll(currentTableScroll);
        setSelectedRowKeys([]);
    }, [hits]);

    const onClickRow = (record) => {
        return {
            onClick: () => {
                setSelectedRowKeys([record.item._dataObjectId]);
                onDataObjectSelected && onDataObjectSelected(record.item);
            },
            onFocus: () => {
                setSelectedRowKeys([record.item._dataObjectId]);
                onDataObjectSelected && onDataObjectSelected(record.item);
            },
        };
    }

    return (
        <Table
            dataSource={hits}
            size="small"
            rowKey={(hit) => hit.item._dataObjectId}
            scroll={tableScroll}
            loading={loading ? { size: "large", tip: "Getting search result..." } : false}
            className={[...tableClasses, "schema-list-table", "default-row"].join(" ")}
            pagination={{
                position: ["bottomRight"],
                defaultPageSize: 100,
                pageSize: 100,
                responsive: true,
                showSizeChanger: false,
                total: totalHits
            }}
            onChange={(pagination, filters, sorter, extra) => extra.action === "paginate" && onPageSelect && onPageSelect(pagination)}
            onRow={onClickRow}
            rowSelection={{
                selectedRowKeys,
                type: 'radio'
            }}>
            {
                [
                    <Column
                        key={"_businessAreaId"}
                        dataIndex={["item", "_businessAreaName"]}
                        title={"Business Area"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        shouldCellUpdate={(record, prevRecord) => {
                            return record !== prevRecord;
                        }}
                        render={(value, hit, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                    />,
                    <Column
                        key={"_schemaId"}
                        dataIndex={["item", "_schemaName"]}
                        title={"Schema"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        shouldCellUpdate={(record, prevRecord) => {
                            return record !== prevRecord;
                        }}
                        render={(value, hit, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                    />,
                    <Column
                        key={"_dataSetId"}
                        dataIndex={["item", "_dataSetName"]}
                        title={"Dataset"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        shouldCellUpdate={(record, prevRecord) => {
                            return record !== prevRecord;
                        }}
                        render={(value, hit, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                    />,
                    <Column
                        key={"_dataObjectId"}
                        dataIndex={["item", "_dataObjectId"]}
                        title={"RecordID"}
                        ellipsis={{
                            showTitle: false,
                        }}
                        shouldCellUpdate={(record, prevRecord) => {
                            return record !== prevRecord;
                        }}
                        render={(value, hit, index) => <Tooltip placement="topLeft" title={value}><DataObjectLink dataObjectId={value}></DataObjectLink></Tooltip>}
                    />,
                    ...highlightColumns.tagColumns.map(tagColumn => <Column
                        key={tagColumn}
                        dataIndex={["highlight", tagColumn]}
                        title={<Tag>{tagColumn}</Tag>}
                        shouldCellUpdate={(record, prevRecord) => {
                            return record !== prevRecord;
                        }}
                        render={(value, hit, index) => {
                            if (value) {
                                return <HighlightValue highlightValues={value}></HighlightValue>
                            }
                            return <></>
                        }}
                    />),
                    ...([...Array(highlightColumns.indexColumnCount)].map((item, index) => {
                        return <Column
                            key={index}
                            dataIndex={["highlight", index]}
                            title={<Text type="secondary">{`Untagged Field(${index + 1})`}</Text>}
                            shouldCellUpdate={(record, prevRecord) => {
                                return record !== prevRecord;
                            }}
                            render={(value, hit, index) => {
                                if (value) {
                                    return <HighlightValue highlightValues={value}></HighlightValue>
                                }
                                return <></>
                            }}
                        />
                    }) || [])
                ]
            }
        </Table>
    );
}

export default DataObjectSearchResultList;