import React from "react";
import { Switch, Space, Button, Table, Checkbox, Row, Col, Tooltip } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea, FullHeightContainerLayout } from "components";

const { Column } = Table;

const DataSyndicationSourceList = ({ items, onAddSource, onViewSource, onEditSource, onDeleteSource, onEnableDisableSource, permission = { canEdit: true, canView: true, canDelete: true, canAdd: true } }) => {
    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }

    return <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
        <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={true}
                content={
                    <Table size="small"
                        rowKey="sourceId"
                        dataSource={items}
                        pagination={false}
                        scroll={{ y: "100vh" }}
                        className="container-height-100">
                        <Column
                            width="6em"
                            render={(value, source, index) => <>
                                <Tooltip title='Edit source'>
                                    <Icon
                                        disabled={!permission.canEdit}
                                        color='blue'
                                        name='pencil'
                                        className="action-cursor"
                                        onClick={() => onEditSource(source.sourceId)} /> </Tooltip>
                                <Tooltip title='View source'>
                                    <Icon
                                        disabled={!permission.canView}
                                        color='blue'
                                        name='eye'
                                        className="action-cursor"
                                        onClick={() => onViewSource(source.sourceId)} /> </Tooltip>
                            </>}
                        />
                        <Column
                            width="15%"
                            dataIndex="name"
                            title="Name"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, source, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                        />
                        <Column
                            width="15%"
                            dataIndex="description"
                            title="Purpose"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, source, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                        />
                        <Column
                            dataIndex="validFrom"
                            title="Valid From"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, source, index) => value && <Tooltip placement="topLeft" title={value}>{moment.utc(value).toDate().toLocaleString()}</Tooltip>}
                        />
                        <Column
                            dataIndex="validUntil"
                            title="Valid Until"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, source, index) => value && <Tooltip placement="topLeft" title={value}>{moment.utc(value).toDate().toLocaleString()}</Tooltip>}
                        />
                        <Column
                            dataIndex="invocationDate"
                            title="Last Invoked Date"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, source, index) => value && <Tooltip placement="topLeft" title={value}>{moment.utc(value).toDate().toLocaleString()}</Tooltip>}
                        />
                        <Column
                            dataIndex="createdDate"
                            title="Created Date"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, source, index) => <Tooltip placement="topLeft" title={value}>{moment.utc(value).toDate().toLocaleString()}</Tooltip>}
                        />
                        <Column
                            dataIndex="updatedDate"
                            title="Updated Date"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, source, index) => <Tooltip placement="topLeft" title={value}>{moment.utc(value).toDate().toLocaleString()}</Tooltip>}
                        />
                        <Column
                            width="5%"
                            render={(value, source, index) => {
                                return (


                                    <Tooltip title='Delete source'>
                                        <Icon
                                            disabled={!permission.canDelete}
                                            color='blue'
                                            name='trash alternate outline'
                                            className="action-cursor"
                                            onClick={() => onDeleteSource(source)} /> </Tooltip>
                                );
                            }}
                        />
                    </Table>}
                footer={
                    <Row className="table-footer-row">
                        <Col span={24} className="footer-right-column">
                            <Button type="primary"
                                disabled={!permission.canAdd}
                                onClick={onAddSource}>
                                <PlusOutlined /> Add Syndication Source
                            </Button>
                        </Col>
                    </Row>}
                footerStyle={{ paddingTop: "1rem" }}>

            </FullHeightContainerLayout>
        </Col>
    </Row>
};

export default DataSyndicationSourceList;