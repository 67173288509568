import React from 'react'
import { Message } from 'semantic-ui-react'

/** @type {import('./types').ProhibitedArea} */
const ProhibitedArea = () => (
    <Message negative floating size='big'>
        <Message.Header>Permission Denied</Message.Header>
        <p>You don't have permisson to enter in this area.</p>
    </Message>
)

export default ProhibitedArea;
