import { put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";

function* watchSetDataMapperFlow(action: IDataMapperFlowAction) {
    yield put(actions.setDataMapperFlowAction(action.payload));
}

export default function* DataMapperFlowSaga() {
    yield takeLatest("SET_DATA_MAPPER_FLOW", watchSetDataMapperFlow);
}
