import React, { useState } from 'react';
import { Form, Input, Button, Table, Checkbox, Select, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import scrollIntoView from 'scroll-into-view';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import { FullHeightContainerLayout } from 'components';
import CypressTestIds from 'cypress/CypressTestIds';

const { Column } = Table;
const { Option } = Select;

const RolePermissionList = ({ formRef, permissionMasterData, permissions, onPermissionChange, readOnly, onDeletePermission, getFormErrors, add, remove }) => {
    const [scrollToLastRow, setScrollToLastRow] = useState(false);

    const isPermissionReadOnly = (name, actionProp) => {
        if (formRef && formRef.current) {
            let permissionName = formRef.current.getFieldValue(["permissions", name, "name"]);
            if (permissionName) {
                let masterPermission = permissionMasterData.find(item => item.name === permissionName);
                if (masterPermission && masterPermission.readOnly) {
                    return true;
                }
            }
        }
        return false;
    }

    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={true}
            content={
                <Table size="small"
                    dataSource={permissions}
                    rowKey="key"
                    pagination={false}
                    scroll={{ y: "100vh" }}
                    className="form-table container-height-100"
                    data-testid={CypressTestIds.ROLES_PERMISSIONS_LIST_TABLE}>
                    <Column
                        width="5%"
                        title="No."
                        key="index"
                        render={(value, field, index) => index + 1}
                    />
                    <Column
                        title={"Permission"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "name"]}
                                    {...getFormErrors(`permissions.${field.name}.name`)}>
                                    <Select
                                        data-testid={CypressTestIds.ROLES_PERMISSIONS_LIST_PERMISSION_NAME_SELECT}
                                        showSearch
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={value => onPermissionChange(value, field)}
                                        {...(() => (readOnly ? { open: false } : {}))()}>
                                        {permissionMasterData.map(permission => (<Option key={permission.name} value={permission.name}>{permission.name}</Option>))}
                                    </Select>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        title={"Description"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "description"]}
                                    {...getFormErrors(`permissions.${field.name}.description`)}
                                    rules={descriptionRules}>
                                    <Input readOnly={readOnly} data-testid={CypressTestIds.ROLES_PERMISSIONS_LIST_PERMISSION_DESCRIPTION_INPUT}></Input>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="6%"
                        title={"Active"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "active"]}
                                    valuePropName="checked"
                                    initialValue={true}>
                                    <Checkbox disabled={readOnly} data-testid={CypressTestIds.ROLES_PERMISSIONS_LIST_ACTIVE_CHECK}></Checkbox>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="6%"
                        title={"Add"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "canAdd"]}
                                    valuePropName="checked"
                                    dependencies={[field.name, "name"]}
                                    initialValue={true}>
                                    <Checkbox disabled={isPermissionReadOnly(field.name, "canAdd") || readOnly} data-testid={CypressTestIds.ROLES_PERMISSIONS_LIST_CAN_ADD_CHECK}></Checkbox>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="6%"
                        title={"View"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "canView"]}
                                    valuePropName="checked"
                                    initialValue={true}>
                                    <Checkbox disabled={readOnly} data-testid={CypressTestIds.ROLES_PERMISSIONS_LIST_CAN_VIEW_CHECK}></Checkbox>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="6%"
                        title={"Edit"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "canEdit"]}
                                    valuePropName="checked"
                                    dependencies={[field.name, "name"]}
                                    initialValue={true}>
                                    <Checkbox disabled={isPermissionReadOnly(field.name, "canEdit") || readOnly} data-testid={CypressTestIds.ROLES_PERMISSIONS_LIST_CAN_EDIT_CHECK}></Checkbox>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="6%"
                        title={"Delete"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "canDelete"]}
                                    valuePropName="checked"
                                    dependencies={[field.name, "name"]}
                                    initialValue={true}>
                                    <Checkbox disabled={isPermissionReadOnly(field.name, "canDelete") || readOnly} data-testid={CypressTestIds.ROLES_PERMISSIONS_LIST_CAN_DELETE_CHECK}></Checkbox>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="5%"
                        render={(value, field, index) => {
                            return (
                                <Icon
                                    color='blue'
                                    name='trash alternate'
                                    className="action-cursor"
                                    onClick={() => {
                                        onDeletePermission(field, () => remove(field.name));
                                    }}
                                    disabled={readOnly} 
                                    data-testid={CypressTestIds.ROLES_PERMISSIONS_LIST_PERMISSION_DELETE_BUTTON}/>
                            );
                        }}
                    />
                </Table>
            }
            footer={
                <Form.Item>
                    <Button
                        block
                        onClick={() => {
                            setScrollToLastRow(true);
                            add();
                        }}
                        disabled={readOnly}
                        data-testid={CypressTestIds.ROLES_PERMISSIONS_LIST_ADD_PERMISSION_BUTTON}>
                        <PlusOutlined />
                        {permissions.length === 0 ? "Add permission" : "Add another permission"}
                    </Button>
                </Form.Item>
            }>
        </FullHeightContainerLayout>
    );
}

export default RolePermissionList;