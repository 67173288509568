import { CheckOutlined } from "@ant-design/icons";
import { Button, Flex, Input, Popover, Space } from "antd";
import { SUPPORTED_FORMULAS } from "hot-formula-parser";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import FormulaSuggestionOptionContent from "./FormulaSuggestionOptionContent";
import FormulaSuggestionOptionTitle from "./FormulaSuggestionOptionTitle";

type FormulaSuggestionInputProps = {
    sourceColumns: string[];
    onSelect: (selected: IFormulaFunction, parentId?: string) => void;
};

// Ensure no repeated formulas:
// e.g. SPLIT is duplicated in original SUPPORTED_FORMULAS
const noRepeatedSupportedFormula = [...SUPPORTED_FORMULAS]
    .sort()
    .filter(function (item, pos, ary) {
        return !pos || item !== ary[pos - 1];
    });

export default function FormulaSuggestionInput({
    sourceColumns,
    onSelect,
}: FormulaSuggestionInputProps) {
    const [formula, setFormula] = React.useState<string>("");
    const [openSuggestion, setOpenSuggestion] = React.useState<boolean>(false);

    const sortedSourceColumns = React.useMemo(
        () =>
            sourceColumns.sort().filter(function (item, pos, ary) {
                return !pos || item !== ary[pos - 1];
            }),
        [sourceColumns],
    );

    const handleInsertFormula = React.useCallback(
        (selected: IFormulaFunction) => {
            setFormula(selected.value);
            setOpenSuggestion(false);
        },
        [],
    );

    const handleConfirmInput = React.useCallback(() => {
        setOpenSuggestion(false);
        const hasVariable = sourceColumns.find(
            (v) => v.toLowerCase() === formula.toLowerCase(),
        );
        if (hasVariable) {
            // Found variable
            onSelect({
                id: uuidv4(),
                label: hasVariable,
                value: hasVariable,
                type: "variable",
            });
            // Clear input
            setFormula("");
            return;
        }

        const hasFormula = noRepeatedSupportedFormula.find(
            (f) => f.toLowerCase() === formula.toLowerCase(),
        );
        if (hasFormula) {
            // Found formula
            onSelect({
                id: uuidv4(),
                label: hasFormula,
                value: hasFormula,
                type: "formula",
            });
            // Clear input
            setFormula("");
            return;
        }

        // Simply a text
        onSelect({
            id: uuidv4(),
            label: formula,
            value: formula,
            type: "string",
        });
        // Clear input
        setFormula("");
    }, [formula, onSelect, sourceColumns]);

    const variableHintOptions = React.useMemo((): IFormulaFunction[] => {
        const _hints: IFormulaFunction[] = [];
        sortedSourceColumns.forEach((sc) => {
            sc.toLowerCase().includes(formula.toLowerCase()) &&
                _hints.push({
                    label: sc,
                    value: sc,
                    type: "variable",
                });
        });

        return _hints;
    }, [sortedSourceColumns, formula]);

    const formulaHintOptions = React.useMemo((): IFormulaFunction[] => {
        // On load, populate autocomplete hint options
        const _hints: IFormulaFunction[] = [];
        noRepeatedSupportedFormula.forEach((sf) => {
            sf.toLowerCase().includes(formula.toLowerCase()) &&
                _hints.push({
                    label: sf,
                    value: sf,
                    type: "formula",
                });
        });
        return _hints;
    }, [formula]);

    const content = React.useMemo((): React.ReactNode => {
        return (
            <div style={containerStyle}>
                <Flex
                    style={{
                        width: "100%",
                    }}
                    vertical
                >
                    {variableHintOptions.length > 0 && (
                        <>
                            <FormulaSuggestionOptionTitle title="Source Variables" />
                            {variableHintOptions.map((o) => (
                                <FormulaSuggestionOptionContent
                                    key={`formula-${o.value.trim()}`}
                                    label={o.label}
                                    onClick={() => handleInsertFormula(o)}
                                />
                            ))}
                        </>
                    )}
                    {formulaHintOptions.length > 0 && (
                        <>
                            <FormulaSuggestionOptionTitle title="Supported Formula" />
                            {formulaHintOptions.map((o) => (
                                <FormulaSuggestionOptionContent
                                    key={`formula-${o.value.trim()}`}
                                    label={o.label}
                                    onClick={() => handleInsertFormula(o)}
                                />
                            ))}
                        </>
                    )}
                    {formulaHintOptions.length === 0 &&
                        variableHintOptions.length === 0 && (
                            <p>No match found.</p>
                        )}
                </Flex>
            </div>
        );
    }, [formulaHintOptions, handleInsertFormula, variableHintOptions]);

    return (
        <Space.Compact block>
            <Popover
                content={content}
                arrow={false}
                placement="bottomLeft"
                open={openSuggestion}
            >
                <Input
                    size="small"
                    value={formula}
                    placeholder="Enter variable or formula"
                    onChange={(e) => setFormula(e.currentTarget.value)}
                    onFocus={() => setOpenSuggestion(true)}
                    onBlur={() => {
                        // Wait for a while before closing Suggestion panel
                        // so that any clicked event can be captured first
                        setTimeout(() => setOpenSuggestion(false), 100);
                    }}
                    className="nodrag"
                    style={{
                        minWidth: "200px",
                    }}
                />
            </Popover>
            {formula.length > 0 && (
                <Button
                    size="small"
                    shape="default"
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={handleConfirmInput}
                />
            )}
        </Space.Compact>
    );
}

const containerStyle: React.CSSProperties = {
    width: "100%",
    maxWidth: "300px",
    maxHeight: "80vh",
    overflow: "auto",
    scrollbarColor: "#999 transparent",
    scrollbarWidth: "thin",
};
