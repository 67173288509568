import React, { useState } from 'react';
import { Row, Col, Button, Space } from 'antd';
import { FullHeightContainerLayout, SystemConnectionSelector } from 'components';
import CypressTestIds from 'cypress/CypressTestIds';

const DataSetExport = ({ businessAreaId, onExport, onCancel }) => {
    const [selectedConnection, setSelectedConnection] = useState(null)
    const onSelectConnection = (connection) => {
        setSelectedConnection(connection);
    }

    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={true}
            content={
                <SystemConnectionSelector
                    businessAreaId={businessAreaId}
                    onConnectionSelectionComplete={onSelectConnection}
                    allowNewConnectionDestination={true}
                    readSelectedDestination={false}
                    allowedFileTypes={["txt", "csv"]}>
                </SystemConnectionSelector>
            }
            footer={
                <Row className="table-footer-row">
                    <Col span={24} className="footer-right-column">
                        <Space>
                            <Button onClick={() => onCancel && onCancel()} data-testid={CypressTestIds.DATASET_EXPORT_CANCEL_BUTTON}>
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                disabled={!selectedConnection}
                                onClick={() => onExport && onExport(selectedConnection)} data-testid={CypressTestIds.DATASET_EXPORT_EXPORT_BUTTON}>
                                Export
                            </Button>
                        </Space>
                    </Col>
                </Row>
            }>
        </FullHeightContainerLayout>
    );
}

export default DataSetExport;