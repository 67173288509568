import React, { useState } from "react";
import { Tooltip, Row, Col, Tag, Table, Progress } from "antd";
import { WarningTwoTone, CheckCircleOutlined, MinusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { FieldInsightProgress, FullHeightContainerLayout } from "components";
import numeral from "numeral";
import CypressTestIds from 'cypress/CypressTestIds';

const { Column } = Table;

const DataSetInsightsList = ({
    dataSetInsightList
}) => {
    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={false}
            content={
                <div className="full-height-flex-container verticle-scroll">
                    <div className="full-height-flex-container">
                        <Table size="small"
                            rowKey="fieldId"
                            dataSource={dataSetInsightList}
                            rowSelection={{
                                type: 'radio'
                            }}
                            pagination={false}
                            scroll={{ y: "100vh" }}
                            className="container-height-100 schema-list-table"
                            data-testid={CypressTestIds.DATASET_INSIGHTS_TABLE}>
                            <Column
                                width="25%"
                                dataIndex={"businessAreaName"}
                                title={"Business Area"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSetInsight, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="25%"
                                dataIndex={"schemaName"}
                                title={"Schema"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSetInsight, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="25%"
                                dataIndex={"dataSetName"}
                                title={"DataSet"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSetInsight, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="25%"
                                dataIndex={"fieldName"}
                                title={"Attribute/Column"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSetInsight, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="25%"
                                dataIndex={"fieldClassifiers"}
                                title={"Classifiers/Tags"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSetInsight, index) => {
                                    return (value && value.length > 0 ?
                                        <Row>
                                            <Col className="gutter-row" key={value[0].tagId}><Tag key={value[0].tagId}>{value[0].tagName}</Tag></Col>
                                            {
                                                value.length > 1 ?
                                                    <Col className="gutter-row" key={value[1].tagId}>
                                                        <Tooltip title={value.slice(1).map(tag => tag.tagName).join(", ")}>
                                                            <Tag>...</Tag>
                                                        </Tooltip>
                                                    </Col>

                                                    :
                                                    <></>
                                            }
                                        </Row>
                                        :
                                        <></>
                                    );
                                }}
                            />
                            <Column
                                width="25%"
                                dataIndex={"fieldDataType"}
                                title={"Data Type"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSetInsight, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="15rem"
                                dataIndex={"successDataCount"}
                                title={"Validity to Schema"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSetInsight, index) => <FieldInsightProgress
                                    successDataCount={dataSetInsight.successDataCount}
                                    erroredDataCount={dataSetInsight.erroredDataCount}
                                    fieldDataType={dataSetInsight.fieldDataType} >
                                </FieldInsightProgress>}
                            />
                        </Table>
                    </div>
                </div>}>
        </FullHeightContainerLayout>
    );
};

export default DataSetInsightsList;