import { Flex, Typography } from "antd";
import FormulaDictionary from "components/atoms/FormulaDictionary";
import React from "react";

type FormulaSuggestionOptionContentProps = {
    label?: string;
    children?: React.ReactNode;
    onClick?: () => void;
};

const formulaDefinitions = FormulaDictionary();

export default function FormulaSuggestionOptionContent({
    label,
    children,
    onClick,
}: FormulaSuggestionOptionContentProps) {
    const [hovered, setHovered] = React.useState<boolean>(false);

    return (
        <Flex
            vertical
            align="flex-start"
            style={{
                ...containerStyle,
                ...(hovered ? { backgroundColor: "#f1f1f1" } : {}),
            }}
            onClick={onClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Typography>{label}</Typography>
            {label && formulaDefinitions.has(label) && (
                <Flex
                    vertical
                    align="flex-start"
                    style={{ paddingLeft: "1em" }}
                >
                    <span
                        style={{
                            whiteSpace: "wrap",
                            fontSize: "0.8em",
                            fontWeight: "normal",
                            color: "#666",
                        }}
                    >
                        {formulaDefinitions.get(label)?.description}
                    </span>
                    <span
                        style={{
                            whiteSpace: "wrap",
                            fontSize: "0.8em",
                            fontStyle: "italic",
                        }}
                    >
                        {formulaDefinitions.get(label)?.syntax}
                    </span>
                </Flex>
            )}
            {children}
        </Flex>
    );
}

const containerStyle: React.CSSProperties = {
    padding: "0.3rem 1rem",
    cursor: "pointer",
};
