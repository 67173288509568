import {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    Connection,
    Controls,
    Edge,
    EdgeChange,
    MarkerType,
    Node,
    NodeTypes,
    OnEdgesChange,
    OnEdgesDelete,
    OnNodesChange,
    Panel,
    Position,
    ReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useAppDispatch } from "hooks";
import React from "react";
import { setDataMapperFlowAction } from "store/dataMapperFlow/actions";
import DataMappedNode from "./DataMappedNode";
import RowInputPanel from "./RowInputPanel";
import SourceNode from "./SourceNode";
import TargetNode from "./TargetNode";

const nodeTypes: NodeTypes = {
    sourceNode: SourceNode,
    targetNode: TargetNode,
    dataMappedNode: DataMappedNode,
};

type DataMapperFlowProps = {
    destinationFields: IMappingDestinationField[];
    sourceFields: string[];
    sourceData: IFormulaParserDataInput[];
    onMappingChanged: (fields: IMappingDestinationField[]) => void;
};

const topPadding = 100;
const verticalGap = 150;
const horizontalGap = 300;

export default function DataMapperFlow({
    destinationFields,
    sourceFields,
    sourceData,
}: DataMapperFlowProps) {
    const dispatch = useAppDispatch();
    const [nodes, setNodes] = React.useState<Node[]>([]);
    const [edges, setEdges] = React.useState<Edge[]>([]);
    const [sourceDataRow, setSourceDataRow] = React.useState<number>(1); // Defaults to first row

    React.useEffect(() => {
        dispatch(
            setDataMapperFlowAction({ data: sourceData, row: sourceDataRow }),
        );
    }, [dispatch, sourceData, sourceDataRow]);

    React.useEffect(() => {
        const _nodes: Node[] = [];
        sourceFields.forEach((sf, index) => {
            _nodes.push({
                id: sf,
                type: "sourceNode",
                sourcePosition: Position.Right,
                selectable: false,
                position: {
                    x: horizontalGap,
                    y: topPadding + verticalGap * index,
                },
                data: {
                    label: sf,
                    column: sf,
                },
            });
        });
        destinationFields.forEach((df, index) => {
            _nodes.push({
                id: df.fieldId,
                type: "targetNode",
                targetPosition: Position.Left,
                position: {
                    x: horizontalGap * 2,
                    y: topPadding + verticalGap * index,
                },
                data: {
                    label: df.name,
                    dataType: df.dataType,
                },
            });
        });
        setNodes(_nodes);
    }, [destinationFields, sourceData.length, sourceFields]);

    const onConnect = React.useCallback(
        (params: Edge | Connection) =>
            setEdges((eds) =>
                addEdge(
                    {
                        ...params,
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                            width: 20,
                            height: 20,
                            color: "#b1b1b7",
                        },
                    },
                    eds,
                ),
            ),
        [],
    );

    const onEdgesChange: OnEdgesChange = React.useCallback(
        (changes: EdgeChange<Edge>[]) =>
            setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges],
    );

    const onEdgesDelete: OnEdgesDelete = React.useCallback(
        (changes: Edge[]) => {
            changes.forEach((edge) => {
                // Remove all Target's children nodes
                setNodes(nodes.filter((n) => n.parentId !== edge.target));
            });
        },
        [nodes, setNodes],
    );

    const onNodesChange: OnNodesChange = React.useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes],
    );

    return (
        <div style={flowContainerStyle}>
            <ReactFlow
                fitView
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                onConnect={onConnect}
                onEdgesChange={onEdgesChange}
                onNodesChange={onNodesChange}
                onEdgesDelete={onEdgesDelete}
            >
                <Background />
                <Controls />
                <Panel position="top-left">
                    <RowInputPanel
                        label="Sample Data Row"
                        note={`Enter a value between 1 and ${sourceData.length}`}
                        onChange={(value: string) => {
                            if (value && parseInt(value) < sourceData.length) {
                                setSourceDataRow(parseInt(value));
                            }
                        }}
                    />
                </Panel>
            </ReactFlow>
        </div>
    );
}

const flowContainerStyle: React.CSSProperties = {
    width: "100vw",
    height: "100vh",
    fontSize: "14px",
    fontFamily:
        "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
};
