import { Handle, Node, NodeProps, useReactFlow } from "@xyflow/react";
import { Select } from "antd";
import convertFormulaBuilderToString from "common/convertFormulaBuilderToString";
import { useAppSelector } from "hooks";
import { Parser } from "hot-formula-parser";
import React, { memo } from "react";
import FormulaBuilder from "../FormulaBuilder";

export type DataMappedNodeType = Node<
    {
        dataType: string;
        label: string;
        sourceColumns: string[];
        sourceIds: string[];
        targetIds: string[];
    },
    "dataMappedNode"
>;

type IDataMappingOption = {
    value: IDataMappingType;
    label: string;
};

const dataMappingOptions: IDataMappingOption[] = [
    {
        value: "DATA_COLUMN",
        label: "Data column",
    },
    {
        value: "FORMULA",
        label: "Formula",
    },
];

// Recursively loop through children to find ID and insert function
function insertBuilderFunction(
    fb: IFormulaBuilder,
    parentId: string,
    newFunction: IFormulaFunction,
) {
    if (fb.formulaFunction.id === parentId) {
        if (fb.children) {
            fb.children.push({
                formulaFunction: newFunction,
            });
        } else {
            fb.children = [
                {
                    formulaFunction: newFunction,
                },
            ];
        }
    } else if (fb.children) {
        fb.children.forEach((child) =>
            insertBuilderFunction(child, parentId, newFunction),
        );
    }
}

function DataMappedNode({
    id,
    data,
    isConnectable,
    targetPosition,
    sourcePosition,
}: NodeProps<DataMappedNodeType>) {
    const { updateNodeData } = useReactFlow();
    const [mappingType, setMappingType] = React.useState<IDataMappingType>(
        data.dataType as IDataMappingType,
    );
    const { data: sourceData, row } = useAppSelector(
        (state) => state.dataMapperFlow,
    );
    const [builder, setBuilder] = React.useState<IFormulaBuilder>();
    const [sampleDataCol, setSampleDataCol] = React.useState<string>("");
    const [sampleComputed, setSampleComputed] = React.useState<string>("");

    const handleChangeMappingType = React.useCallback(
        (newType: IDataMappingType) => {
            setMappingType(newType);
            updateNodeData(id, {
                ...data,
                dataType: newType,
            });
        },
        [data, id, updateNodeData],
    );

    React.useEffect(() => {
        if (!sourceData || !data.sourceColumns) {
            return;
        }

        if (mappingType === "DATA_COLUMN") {
            const samples: string[] = [];
            data.sourceColumns.forEach((sc) => {
                if (sourceData[row][sc]) {
                    samples.push(sourceData[row][sc]);
                }
            });
            setSampleDataCol(samples.join(" "));
        }
    }, [data, sourceData, row, mappingType]);

    React.useEffect(() => {
        if (!sourceData || !builder) {
            return;
        }

        if (mappingType === "FORMULA") {
            const derivedFormula = convertFormulaBuilderToString(builder);
            const parser = new Parser();
            derivedFormula.variables.forEach((variable) => {
                parser.setVariable(
                    variable.value,
                    sourceData[row][variable.columnName] || "Unknown Variable",
                );
            });
            const computed = parser.parse(derivedFormula.formula);

            setSampleComputed(`${computed.result}`);
        }
    }, [builder, sourceData, row, mappingType]);

    const handleBuilder = React.useCallback(
        (newFunction: IFormulaFunction, parentId?: string) => {
            if (!builder) {
                setBuilder({
                    formulaFunction: newFunction,
                });
            } else {
                const changedBuilder: IFormulaBuilder =
                    structuredClone(builder);
                if (parentId) {
                    insertBuilderFunction(
                        changedBuilder,
                        parentId,
                        newFunction,
                    );
                }
                setBuilder(changedBuilder);
            }
        },
        [builder],
    );

    const handleResetBuilder = React.useCallback(() => {
        setBuilder(undefined);
        setSampleComputed("");
    }, []);

    return (
        <>
            {isConnectable && targetPosition && (
                <Handle
                    id="handle-target"
                    type="target"
                    position={targetPosition}
                    style={handleStyle}
                    className="bg-slate-500"
                />
            )}
            <div className="flex flex-row items-start gap-3 p-3 bg-slate-100 rounded-lg min-w-40 min-h-16">
                <div className="flex flex-col gap-2">
                    {data.sourceIds.length === 1 ? (
                        <div className="flex flex-row items-center gap-2">
                            <span className="text-gray-600">Mapping Type:</span>
                            <Select
                                className="nodrag flex-1"
                                size="small"
                                defaultValue="DATA_COLUMN"
                                onChange={(value: IDataMappingType) =>
                                    handleChangeMappingType(value)
                                }
                                value={mappingType}
                                options={dataMappingOptions}
                            />
                        </div>
                    ) : (
                        <span
                            style={{
                                fontSize: "0.8em",
                                alignSelf: "flex-start",
                                color: "#999",
                            }}
                        >
                            Mapping type:{" "}
                            <span className="text-gray-600">
                                {data.dataType}
                            </span>
                        </span>
                    )}
                    {mappingType === "FORMULA" && (
                        <FormulaBuilder
                            sourceColumns={data.sourceColumns}
                            builder={builder}
                            onSelect={handleBuilder}
                            onReset={handleResetBuilder}
                        />
                    )}
                    {((mappingType === "FORMULA" && sampleComputed) ||
                        (mappingType === "DATA_COLUMN" && sampleDataCol)) && (
                        <div className="flex flex-row gap-2 self-stretch">
                            <span className="text-gray-600">Sample:</span>
                            <span className="flex-1">
                                {mappingType === "FORMULA"
                                    ? sampleComputed
                                    : sampleDataCol}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            {isConnectable && sourcePosition && (
                <Handle
                    id="handle-source"
                    type="source"
                    position={sourcePosition}
                    style={handleStyle}
                    className="bg-slate-500"
                />
            )}
        </>
    );
}

const handleStyle: React.CSSProperties = {
    backgroundColor: "#64748b",
    height: "0.8rem",
    width: "0.8rem",
};

export default memo(DataMappedNode);
