export const GET_JDBC_CONNECTIONS_REQUEST = "GET_JDBC_CONNECTIONS_REQUEST";
export const GET_JDBC_CONNECTIONS_SUCCESS = "GET_JDBC_CONNECTIONS_SUCCESS";
export const GET_JDBC_CONNECTIONS_FAILURE = "GET_JDBC_CONNECTIONS_FAILURE";

export const CREATE_JDBC_CONNECTION_REQUEST = "CREATE_JDBC_CONNECTION_REQUEST";
export const CREATE_JDBC_CONNECTION_SUCCESS = "CREATE_JDBC_CONNECTION_SUCCESS";
export const CREATE_JDBC_CONNECTION_FAILURE = "CREATE_JDBC_CONNECTION_FAILURE";

export const GET_JDBC_CONNECTION_REQUEST = "GET_JDBC_CONNECTION_REQUEST";
export const GET_JDBC_CONNECTION_SUCCESS = "GET_JDBC_CONNECTION_SUCCESS";
export const GET_JDBC_CONNECTION_FAILURE = "GET_JDBC_CONNECTION_FAILURE";

export const UPDATE_JDBC_CONNECTION_REQUEST = "UPDATE_JDBC_CONNECTION_REQUEST";
export const UPDATE_JDBC_CONNECTION_SUCCESS = "UPDATE_JDBC_CONNECTION_SUCCESS";
export const UPDATE_JDBC_CONNECTION_FAILURE = "UPDATE_JDBC_CONNECTION_FAILURE";

export const DELETE_JDBC_CONNECTION_REQUEST = "DELETE_JDBC_CONNECTION_REQUEST";
export const DELETE_JDBC_CONNECTION_SUCCESS = "DELETE_JDBC_CONNECTION_SUCCESS";
export const DELETE_JDBC_CONNECTION_FAILURE = "DELETE_JDBC_CONNECTION_FAILURE";

export const getJdbcConnectionsRequest = () => ({
    type: GET_JDBC_CONNECTIONS_REQUEST
})

export const getJdbcConnectionsSuccess = (result) => ({
    type: GET_JDBC_CONNECTIONS_SUCCESS,
    payload: {
        result
    }
})

export const getJdbcConnectionsFailure = (error) => ({
    type: GET_JDBC_CONNECTIONS_FAILURE,
    payload: {
        error
    }
})

export const createJdbcConnectionRequest = (connection) => ({
    type: CREATE_JDBC_CONNECTION_REQUEST,
    params: {
        connection
    }
})

export const createJdbcConnectionSuccess = (connection) => ({
    type: CREATE_JDBC_CONNECTION_SUCCESS,
    payload: {
        connection
    }
})

export const createJdbcConnectionFailure = (error) => ({
    type: CREATE_JDBC_CONNECTION_FAILURE,
    payload: {
        error
    }
})

export const getJdbcConnectionRequest = (connectionId) => ({
    type: GET_JDBC_CONNECTION_REQUEST,
    params: {
        connectionId
    }
})

export const getJdbcConnectionSuccess = (connection) => ({
    type: GET_JDBC_CONNECTION_SUCCESS,
    payload: {
        connection
    }
})

export const getJdbcConnectionFailure = (error) => ({
    type: GET_JDBC_CONNECTION_FAILURE,
    payload: {
        error
    }
})

export const updateJdbcConnectionRequest = (connectionId, updatedJdbcConnection) => ({
    type: UPDATE_JDBC_CONNECTION_REQUEST,
    params: {
        connectionId,
        updatedJdbcConnection
    }
})

export const updateJdbcConnectionSuccess = (connection) => ({
    type: UPDATE_JDBC_CONNECTION_SUCCESS,
    payload: {
        connection
    }
})

export const updateJdbcConnectionFailure = (error) => ({
    type: UPDATE_JDBC_CONNECTION_FAILURE,
    payload: {
        error
    }
})

export const deleteJdbcConnectionRequest = (connectionId) => ({
    type: DELETE_JDBC_CONNECTION_REQUEST,
    params: {
        connectionId
    }
})

export const deleteJdbcConnectionSuccess = (connectionId) => ({
    type: DELETE_JDBC_CONNECTION_SUCCESS,
    payload: {
        connectionId
    }
})

export const deleteJdbcConnectionFailure = (error) => ({
    type: DELETE_JDBC_CONNECTION_FAILURE,
    payload: {
        error
    }
})
