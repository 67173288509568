import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { SystemConnectionJdbcList, LoadingOverlay, ProhibitedArea, ManageSystemConnectionJdbcPostgresql, OkCancelConfirmationBox } from "components";
import { SystemConnectionAudit } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import flatten from 'flat';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import { Row, Col } from "antd";

class SystemConnectionJdbcPostgresql extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busy: false,
            busyMessage: "",
            permissionDenied: false,
            fetchingPermission: false,
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
        if (!this.props.action) {
            this.setState({
                busy: true,
                busyMessage: "Getting connections...",
                fetchingConnections: true
            });
            this.props.getJdbcConnections();
        }
        else {
            if (this.props.connectionId) {
                this.props.getJdbcConnection(this.props.connectionId);
            }
            if (this.props.action === "view" || this.props.action === "edit") {
                this.setState({
                    busy: true,
                    busyMessage: "Getting connection...",
                    fetchingConnection: true
                });
            }
            else if (this.props.action === "history") {
                this.setState({
                    showHistory: false,
                    busy: true,
                    fetchingConnection: true
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.onGetConnectionsResultUpdate(prevProps);

        this.onCreateConnectionResultUpdate(prevProps);

        this.onGetConnectionResultUpdate(prevProps);

        this.onUpdateConnectionResultUpdate(prevProps);

        this.onDeleteConnectionResultUpdate(prevProps);
    }

    onGetConnectionsResultUpdate = (prevProps) => {
        if (this.props.getConnectionsResult && this.props.getConnectionsResult !== prevProps.getConnectionsResult) {
            if (!this.props.getConnectionsResult.success) {
                if (this.props.getConnectionsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get connections at this moment.");
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingConnections: false
            });
        }
    }

    onCreateConnectionResultUpdate = (prevProps) => {
        if (this.props.createConnectionResult && this.props.createConnectionResult !== prevProps.createConnectionResult) {
            if (!this.props.createConnectionResult.success) {
                showError(this.props.createConnectionResult.message || "Connection cannot be created at this moment.");
                this.setState({
                    busy: false,
                    manageConnectionErrors: this.props.createConnectionResult.fields
                });
            }
            else {
                showSuccess("Connection created successfully.", () => {
                    this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", this.props.match.params.tenant)
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
                        .replace(":itemAction", "awss3"));
                });
            }
        }
    }

    onGetConnectionResultUpdate = (prevProps) => {
        if (this.props.getConnectionResult && this.props.getConnectionResult !== prevProps.getConnectionResult) {
            if (!this.props.getConnectionResult.success) {
                if (this.props.getConnectionResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get connection.");
                }
            }
            else {
                if (this.props.action === "history") {
                    this.setState({
                        showHistory: true
                    });
                }
            }
            this.setState({
                busy: false,
                busyMessage: "",
                fetchingConnection: false
            });
            if (this.props.connectionListResult.Items.length === 0) {
                this.showBreadCrumbNavigationItems();
            }
        }
    }

    onUpdateConnectionResultUpdate = (prevProps) => {
        if (this.props.updateConnectionResult && this.props.updateConnectionResult !== prevProps.updateConnectionResult) {
            if (!this.props.updateConnectionResult.success) {
                if (this.props.updateConnectionResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        busy: false,
                        permissionDenied: true
                    });
                }
                else {
                    this.setState({
                        busy: false
                    });
                    showError("Could not able to update connection.");
                }
            }
            else {
                if (this.state.isActivateDeactivateConnection) {
                    showSuccess(`Connection ${this.state.connectionActive ? "activated" : "deactivated"} successfully.`);
                    this.setState({
                        busy: false,
                        isActivateDeactivateConnection: false,
                        connectionActive: false
                    });
                }
                else {
                    this.setState({
                        busyMessage: "Connection updated. Please wait..."
                    });
                    showSuccess("Connection updated successfully.", () => {
                        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", this.props.match.params.tenant)
                            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
                            .replace(":itemAction", "awss3"));
                    });
                }
            }
        }
    }

    onDeleteConnectionResultUpdate = (prevProps) => {
        if (this.props.deleteConnectionResult && this.props.deleteConnectionResult !== prevProps.deleteConnectionResult) {
            if (!this.props.deleteConnectionResult.success) {
                showError(this.props.deleteConnectionResult.message || "Connection cannot be deleted.");
            }
            else {
                showSuccess("Connection deleted successfully.");
            }
            this.setState({
                busy: false
            });
        }
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.showSubBreadCrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", this.props.match.params.tenant)
                        .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
                        .replace(":itemAction", "awss3"),
                    text: "awss3"
                }
            ];
            if (this.props.action) {
                let connectionName = "";
                switch (this.props.action.toLowerCase()) {
                    case "create":
                        breadCrumbItems.push({
                            route: "",
                            text: "New App Client"
                        });
                    case "view":
                    case "edit":
                        connectionName = this.getConnectionName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: connectionName
                        }]);
                        break;
                    case "history":
                        connectionName = this.getConnectionName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: connectionName
                        },
                        {
                            route: "",
                            text: "History"
                        }]);
                        break;
                }
            }

            this.props.showSubBreadCrumbNavigationItems(breadCrumbItems);
        }
    }

    getConnectionName = () => {
        let name = this.props.connectionId;
        if (this.props.connectionListResult.Items && this.props.connectionListResult.Items.length > 0) {
            let connection = this.props.connectionListResult.Items.find(item => item.connectionId === this.props.connectionId);
            if (connection) {
                name = connection.name;
            }
        }
        else if (this.props.connection && this.props.connection.connectionId === this.props.connectionId) {
            name = this.props.connection.name;
        }
        return name;
    }

    showAddConnection = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemId", this.props.match.params.itemAction)
            .replace(":itemAction", "create"));
    }

    showEditConnection = (connectionId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_SUB_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":subItem", "awss3")
            .replace(":itemId", connectionId)
            .replace(":itemAction", "edit"));
    }

    showViewConnection = (connectionId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_SUB_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":subItem", "awss3")
            .replace(":itemId", connectionId)
            .replace(":itemAction", "view"));
    }

    showConnectionHistory = (connectionId) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_SUB_ITEM_ID_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":subItem", "awss3")
            .replace(":itemId", connectionId)
            .replace(":itemAction", "history"));
    }

    cancelCreateConnection = () => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", this.props.match.params.tenant)
            .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
            .replace(":itemAction", "awss3"));
    }

    createConnection = (connection) => {
        this.setState({
            busy: true,
            busyMessage: "Creating connection..."
        });
        this.props.createJdbcConnection(connection);
    }

    updateConnection = (connectionId, updatedConnection) => {
        if (_.isEmpty(updatedConnection) === false) {
            this.setState({
                busy: true,
                busyMessage: "Updating connection..."
            });
            this.props.updateJdbcConnection(connectionId, updatedConnection);
        }
        else {
            this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", this.props.match.params.tenant)
                .replace(":configurationItem", this.props.match.params.configurationItem.toLowerCase())
                .replace(":itemAction", "awss3"));
        }
    }

    onActivateDeactivateConnection = (connection, active) => {
        this.setState({
            isActivateDeactivateConnection: true,
            connectionActive: active,
            busy: true,
            busyMessage: active ? "Activating connection..." : "Deactivating connection..."
        });
        this.props.updateJdbcConnection(connection.connectionId, { version: connection.version, active });
    }

    onConnectionValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.manageConnectionErrors) === false) {
            let manageConnectionErrors = { ...this.state.manageConnectionErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete manageConnectionErrors[key];
            }
            this.setState({
                manageConnectionErrors
            })
        }
    }

    showDeleteConnectionConfirmation = (connection) => {
        this.setState({
            deletedConnection: connection,
            showConnectionDeleteConfirmation: true,
            deleteConnectionConfirmMessage: `Are you sure, you want to delete '${connection.name}' connection?`
        })
    }

    onDeleteConnectionConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Deleting connection..."
        });
        this.props.deleteJdbcConnection(this.state.deletedConnection.connectionId);
        this.onDeleteConnectionCancel();
    }

    onDeleteConnectionCancel = () => {
        this.setState({
            deletedConnection: null,
            showConnectionDeleteConfirmation: false,
            deleteConnectionConfirmMessage: ""
        })
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingConnections || this.state.fetchingPermission);
        if (this.props.action === "view" || this.props.action === "edit") {
            isBusy = isBusy || this.state.fetchingConnection;
        }
        return isBusy;
    }

    getConnectionComponent = () => {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        if (!this.props.action) {
            return (
                <>
                    <OkCancelConfirmationBox
                        show={this.state.showConnectionDeleteConfirmation}
                        message={this.state.deleteConnectionConfirmMessage}
                        onCancel={this.onDeleteConnectionCancel}
                        onOk={this.onDeleteConnectionConfirmed}>
                    </OkCancelConfirmationBox>
                    {
                        !this.state.fetchingConnections
                            ?
                            <SystemConnectionJdbcList
                                permission={this.props.permission}
                                connectionList={this.props.connectionListResult.Items}
                                onAddConnection={this.showAddConnection}
                                onViewConnection={this.showViewConnection}
                                onEditConnection={this.showEditConnection}
                                onDeleteConnection={this.showDeleteConnectionConfirmation}
                                onActivateDeactivateConnection={this.onActivateDeactivateConnection}
                                onViewConnectionHistory={this.showConnectionHistory}
                            />
                            :
                            <></>
                    }
                </>
            );
        }
        switch (this.props.action.toLowerCase()) {
            case "create":
                return <ManageSystemConnectionJdbcPostgresql
                    permission={this.props.permission}
                    action={this.props.action}
                    onSave={this.createConnection}
                    onCancel={this.cancelCreateConnection}
                    formErrors={this.state.manageConnectionErrors}
                    onValuesChanged={this.onConnectionValuesChanged}>
                </ManageSystemConnectionJdbcPostgresql>;
            case "view":
            case "edit":
                return !this.state.fetchingConnection
                    ?
                    (this.props.connection
                        ?
                        <>
                            <OkCancelConfirmationBox
                                show={this.state.showRegenerateSecretConfirmation}
                                message={this.state.regenerateSecretConfirmationMessage}
                                onCancel={this.onRegenerateSecretCancel}
                                onOk={this.onRegenerateSecretConfirmed}>
                            </OkCancelConfirmationBox>
                            <ManageSystemConnectionJdbcPostgresql
                                permission={this.props.permission}
                                action={this.props.action}
                                onSave={this.updateConnection}
                                onCancel={this.cancelCreateConnection}
                                onRegenerateConnectionSecret={this.showRegenerateSecretConfirmation}
                                connection={this.props.connection}
                                formErrors={this.state.manageConnectionErrors}
                                onValuesChanged={this.onConnectionValuesChanged}>
                            </ManageSystemConnectionJdbcPostgresql>
                        </>
                        :
                        (this.props.getConnectionResult.success
                            ?
                            <Message floating size='big'>
                                <Message.Header>Connection not found.</Message.Header>
                                <p>The app you are looking for does not exist.</p>
                            </Message>
                            :
                            <></>
                        )
                    )
                    :
                    <></>;
            case "history":
                return this.state.showHistory ? <SystemConnectionAudit connection={this.props.connection} onClose={this.cancelCreateConnection}></SystemConnectionAudit> : <></>
            default:
                return <ProhibitedArea></ProhibitedArea>
        }
    }

    render() {
        return (
            <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.state.fetchingPermission ? this.getConnectionComponent() : <></>}
                </Col>
            </Row>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        connectionListResult: state.systemConnection.connectionListResult,
        getConnectionsResult: state.systemConnection.getConnectionsResult,
        createConnectionResult: state.systemConnectionJdbc.createConnectionResult,
        getConnectionResult: state.systemConnectionJdbc.getConnectionResult,
        connection: state.systemConnectionJdbc.connection,
        createdConnection: state.systemConnectionJdbc.createdConnection,
        updateConnectionResult: state.systemConnectionJdbc.updateConnectionResult,
        deleteConnectionResult: state.systemConnectionJdbc.deleteConnectionResult,
        permission: { canAdd: true, canEdit: true, canView: true, canDelete: true }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getJdbcConnections: () => dispatch(actions.getJdbcConnectionsRequest()),
        createJdbcConnection: (connection) => dispatch(actions.createJdbcConnectionRequest(connection)),
        getJdbcConnection: (connectionId) => dispatch(actions.getJdbcConnectionRequest(connectionId)),
        updateJdbcConnection: (connectionId, updatedConnection) => dispatch(actions.updateJdbcConnectionRequest(connectionId, updatedConnection)),
        deleteJdbcConnection: (connectionId) => dispatch(actions.deleteJdbcConnectionRequest(connectionId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemConnectionJdbcPostgresql));