import React, { useState } from "react";
import { Button, Table, Checkbox, Row, Col, Tooltip, List, Typography } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea, DataDomainChangedAttributes, FullHeightContainerLayout } from "components";
import _ from 'lodash';
import CypressTestIds from 'cypress/CypressTestIds';

const { Column } = Table;
const { Title, Text, Link } = Typography;

const BusinessAreaDataHistoryList = ({ dataDomainRevisions }) => {
    const [recordDetail, setRecordDetail] = useState(null);
    const [containerHeight, setContainerHeight] = useState("100%");
    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                    <Col span={24}>
                        <FullHeightContainerLayout
                            content={
                                <Table size="small"
                                    data-testid={CypressTestIds.COMMON_OBJECTS_REVISIONS_LIST_TABLE}
                                    rowKey="metadata.txId"
                                    dataSource={dataDomainRevisions}
                                    pagination={false}
                                    scroll={{ y: `100vh` }}
                                    className="container-height-100">
                                    <Column
                                        width="3rem"
                                        title="No."
                                        key="index"
                                        render={(value, item, index) => index + 1}
                                    />
                                    <Column
                                        width="12rem"
                                        dataIndex={["data", "businessAreaName"]}
                                        title={"Business Area"}
                                        render={(value, revision, index) => <>{value || "Global"}</>}
                                    />
                                    <Column
                                        width="10rem"
                                        dataIndex={["data", "domainName"]}
                                        title={"Domain"}
                                        render={(value, revision, index) => <>{value}</>}
                                    />
                                    <Column
                                        width="12rem"
                                        dataIndex={["data", "domainDescription"]}
                                        title={"Domain Description"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                    />
                                    <Column
                                        width="12rem"
                                        dataIndex={["data", "domainPurpose"]}
                                        title={"Domain Purpose"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                    />
                                    <Column
                                        width="10rem"
                                        dataIndex={["data", "usedForTagging"]}
                                        title={"Used for tagging"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, revision, index) => {
                                            return <Checkbox checked={value} onChange={e => ({})}></Checkbox>;
                                        }}
                                    />
                                    <Column
                                        width="15rem"
                                        dataIndex={["data", "createdByEmail"]}
                                        title={"Created By"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                    />
                                    <Column
                                        width="12rem"
                                        dataIndex={["data", "createdDate"]}
                                        title={"Created Date"}
                                        render={(value, revision, index) => <>{moment.utc(value).toDate().toLocaleString()}</>}
                                    />
                                    <Column
                                        width="15rem"
                                        dataIndex={["data", "updatedByEmail"]}
                                        title={"Updated By"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                    />
                                    <Column
                                        width="12rem"
                                        dataIndex={["data", "updatedDate"]}
                                        title={"Updated Date"}
                                        render={(value, revision, index) => <>{moment.utc(value).toDate().toLocaleString()}</>}
                                    />
                                    <Column
                                        width="12rem"
                                        dataIndex={"changes"}
                                        title={"Changes"}
                                        render={(value, revision, index) => {
                                            if (value) {
                                                return <>
                                                    {Object.keys(value).reduce((items, key) => {
                                                        if (Object.keys(value[key]).length > 0) {
                                                            items.push(<Row key={key}>
                                                                <Col>
                                                                    <Link onClick={() => {
                                                                        setContainerHeight("50%");
                                                                        setRecordDetail({
                                                                            name: `${_.capitalize(key)} attributes`,
                                                                            recordId: revision.data.dataDomainId,
                                                                            data: value[key],
                                                                            previousData: revision.previousChanges && revision.previousChanges[key]
                                                                        });
                                                                    }}>
                                                                        {`${key} attributes`}
                                                                    </Link>
                                                                </Col>
                                                            </Row>);
                                                        }
                                                        return items;
                                                    }, [])}
                                                </>
                                            }
                                            return <></>;
                                        }}
                                    />
                                </Table>
                            }
                            showFooter={false}>
                        </FullHeightContainerLayout>

                    </Col>
                </Row>
                {
                    recordDetail
                        ?
                        <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                            <Col span={24}>
                                <FullHeightContainerLayout
                                    showHeader={false}
                                    content={<DataDomainChangedAttributes key={recordDetail.recordId} record={recordDetail}></DataDomainChangedAttributes>}
                                    showFooter={false}>
                                </FullHeightContainerLayout>
                            </Col>
                        </Row>
                        :
                        <></>
                }
            </Col>
        </Row>
    );
};

export default BusinessAreaDataHistoryList;