import {
    CheckCircleOutlined,
    CloseCircleFilled,
    CloseCircleOutlined,
    FileExclamationFilled,
} from "@ant-design/icons";
import React from "react";
import DataPreviewTable, { IDataPreviewDataType } from "./DataPreviewTable";
import "./style.css";

function DataPreview({
    onClose,
    columns,
    data,
    title = "Data Preview (100 Records)",
    dataSchemaColumns = [],
    className = "",
}: IDataPreviewProps) {
    const headers = React.useMemo(() => {
        const columnHeaders: IDataPreviewDataType = {};
        columns.map(
            (column) =>
                (columnHeaders[column] = (
                    <>
                        {column}
                        {dataSchemaColumns &&
                            (dataSchemaColumns.findIndex(
                                (item) =>
                                    item.toLowerCase() === column.toLowerCase(),
                            ) > -1 ? (
                                <CheckCircleOutlined className="text-green-600 ml-1" />
                            ) : (
                                <CloseCircleOutlined className="text-red-600 ml-1" />
                            ))}
                    </>
                )),
        );

        return columnHeaders;
    }, [columns, dataSchemaColumns]);

    return (
        <div className={["flex flex-col flex-1 w-full", className].join(" ")}>
            <div className="flex flex-row justify-between items-center py-2">
                <h3 className="text-sm font-bold">{title}</h3>
                {onClose && (
                    <button
                        onClick={onClose}
                        aria-label="Close"
                        className="text-red-500 hover:text-red-600 text-xl"
                    >
                        <CloseCircleFilled />
                    </button>
                )}
            </div>
            {data.length > 0 ? (
                <div className="scroll-webkit flex-1 w-full overflow-scroll border border-slate-100">
                    <DataPreviewTable headers={headers} rows={data} />
                </div>
            ) : (
                <div className="flex-1 flex flex-col justify-center items-center w-full overflow-scroll border border-slate-300 border-dashed">
                    <FileExclamationFilled className="text-3xl text-slate-500 mb-3" />
                    <p className="text-sm">No data found</p>
                </div>
            )}
        </div>
    );
}

export default DataPreview;
