import React, { useState } from 'react';
import { Form, Input, Button, Table, Checkbox, Select, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout } from 'components';
import CypressTestIds from 'cypress/CypressTestIds';

const { Column } = Table;
const { Option } = Select;

const BusinessAreaUserList = ({ readOnly, userList, users, formRef, add, remove, onUserChange, onDeleteUser, getFormErrors }) => {
    const [scrollToLastRow, setScrollToLastRow] = useState(false);
    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={true}
            content={
                <Table size="small"
                    dataSource={users}
                    pagination={false}
                    scroll={{ y: "100vh" }}
                    className="form-table container-height-100">
                    <Column
                        width="5%"
                        title="No."
                        key="index"
                        render={(value, field, index) => index + 1}
                    />
                    <Column
                        title={"User"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "userId"]}
                                    {...getFormErrors(`users.${field.name}.userId`)}>
                                    <Select
                                        showSearch
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        data-testid={CypressTestIds.BUSINESS_AREA_USER_SELECT}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={value => onUserChange(value, field)}
                                        {...(() => (readOnly ? { open: false } : {}))()}>
                                        {userList.map(user => (<Option key={user.userId} value={user.userId}>{user.email}</Option>))}
                                    </Select>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        title={"Name"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}>
                                    <>{formRef.current.getFieldValue(["users", field.name, "firstName"])} {formRef.current.getFieldValue(["users", field.name, "lastName"])}</>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="6%"
                        title={"Add"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "canAdd"]}
                                    valuePropName="checked"
                                    initialValue={true}>
                                    <Checkbox
                                        disabled={readOnly}
                                        data-testid={CypressTestIds.CAN_ADD_BUSINESS_AREA_USER_CHECKBOX}
                                    />
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="6%"
                        title={"View"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "canView"]}
                                    valuePropName="checked"
                                    initialValue={true}>
                                    <Checkbox
                                        disabled={readOnly}
                                        data-testid={CypressTestIds.CAN_VIEW_BUSINESS_AREA_USER_CHECKBOX}
                                    />
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="6%"
                        title={"Edit"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "canEdit"]}
                                    valuePropName="checked"
                                    initialValue={true}>
                                    <Checkbox
                                        disabled={readOnly}
                                        data-testid={CypressTestIds.CAN_EDIT_BUSINESS_AREA_USER_CHECKBOX}
                                    />
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="6%"
                        title={"Delete"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "canDelete"]}
                                    valuePropName="checked"
                                    initialValue={true}>
                                    <Checkbox
                                        disabled={readOnly}
                                        data-testid={CypressTestIds.CAN_DELETE_BUSINESS_AREA_USER_CHECKBOX}
                                    />
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="6%"
                        title={"Active"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "active"]}
                                    valuePropName="checked"
                                    initialValue={true}>
                                    <Checkbox
                                        disabled={readOnly}
                                        data-testid={CypressTestIds.ACTIVATE_BUSINESS_AREA_USER_CHECKBOX}
                                    />
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="5%"
                        render={(value, field, index) => {
                            return (
                                <Icon
                                    color='blue'
                                    name='trash alternate'
                                    className="action-cursor"
                                    onClick={() => {
                                        onDeleteUser(field, () => remove(field.name));
                                    }}
                                    data-testid={CypressTestIds.DELETE_BUSINESS_AREA_USER_BUTTON}
                                    disabled={readOnly} />
                            );
                        }}
                    />
                </Table>
            }
            footer={
                <Form.Item>
                    <Button
                        block
                        onClick={() => {
                            setScrollToLastRow(true);
                            add();
                        }}
                        data-testid={CypressTestIds.ADD_BUSINESS_AREA_USER_BUTTON}
                        disabled={readOnly}>
                        <PlusOutlined />
                        {users.length === 0 ? "Add user" : "Add another user"}
                    </Button>
                </Form.Item>
            }>
        </FullHeightContainerLayout>
    );
}

export default BusinessAreaUserList;