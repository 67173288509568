import React, { useEffect, useState, useCallback } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { Menu, Icon, Popup, Loader } from 'semantic-ui-react';
import { Tooltip, Typography, Form, Select } from 'antd';
import { usePrevious } from 'common/customHooks';

const { Text } = Typography;
const { Option } = Select;

function SchemaModelFormItem({
    form,
    businessAreaId,
    getSchemaModels,
    getSchemaModelsResult,
    schemaModelListResult,
    formItemProps,
    disabled,
    onChange }) {
    const [fetchingSchemaModels, setFetchingSchemaModels] = useState(false);
    const previousGetSchemaModelsResult = usePrevious(getSchemaModelsResult);
    const [schemaModelsDict, setSchemaModelsDict] = useState({});

    useEffect(() => {
        if (businessAreaId) {
            setFetchingSchemaModels(true);
            getSchemaModels(businessAreaId);
        }
    }, [businessAreaId, getSchemaModels]);

    useEffect(() => {
        const schemaModelsDict = {};
        if (schemaModelListResult && schemaModelListResult.Items && schemaModelListResult.Items.length > 0) {
            for (let schemaModel of schemaModelListResult.Items) {
                schemaModelsDict[schemaModel.schemaId] = schemaModel;
            }

            if (form) {
                const schemaId = form.getFieldValue(formItemProps.name);
                if (schemaId) {
                    onChange(schemaId, schemaModelsDict[schemaId]);
                }
            }
        }
        setSchemaModelsDict(schemaModelsDict);
    }, [schemaModelListResult, form, onChange, formItemProps]);

    useEffect(() => {
        if (getSchemaModelsResult && getSchemaModelsResult !== previousGetSchemaModelsResult) {
            if (!getSchemaModelsResult.success) {
                if (getSchemaModelsResult.code === "PERMISSION_DENIED") {
                    showError("Permission denied.");
                }
                else {
                    showError("Could not able to get Schemas at this moment.");
                }
            }
            setFetchingSchemaModels(false);
        }
    }, [getSchemaModelsResult, schemaModelListResult, previousGetSchemaModelsResult]);

    const onSchemaModelChange = useCallback((schemaId) => {
        if (onChange && schemaModelsDict[schemaId]) {
            onChange(schemaId, schemaModelsDict[schemaId]);
        }
    }, [schemaModelsDict, onChange]);

    return <Form.Item {...formItemProps}>
        <Select
            placeholder="Please select schema"
            loading={fetchingSchemaModels}
            onChange={onSchemaModelChange}
            disabled={disabled || !businessAreaId}>
            {
                schemaModelListResult.Items.map(schemaModel => <Option key={schemaModel.schemaId} value={schemaModel.schemaId}>{schemaModel.name}</Option>)
            }
        </Select>
    </Form.Item>
}

const mapStateToProps = (state, ownProps) => {
    return {
        schemaModelListResult: state.schemaModels.schemaModelListResult,
        getSchemaModelsResult: state.schemaModels.getSchemaModelsResult,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSchemaModels: (businessAreaId) => dispatch(actions.getSchemaModelsRequest(businessAreaId)),
        getSchemaModelsNextPageRequest: (businessAreaId, pageKey) => dispatch(actions.getSchemaModelsNextPageRequest(businessAreaId, pageKey)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SchemaModelFormItem));