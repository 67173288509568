import React, { useState } from 'react';
import { Form, Input, Button, Table, Checkbox, Select, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout } from 'components';
import moment from 'moment';

const { Column } = Table;
const { Option } = Select;

const BusinessAreaSystemConnectionsList = ({ formRef, systemConnectionList, systemConnections, onSystemConnectionChange, readOnly, onDeleteSystemConnection, getFormErrors, add, remove }) => {
    const [scrollToLastRow, setScrollToLastRow] = useState(false);
    const getConnectionTypeLabel = (type) => {
        let displayValue = "";
        if (type === "aws_s3") {
            displayValue = "AWS S3 Connection";
        } else if (type === "jdbc") {
            displayValue = "PostgreSQL Connection";
        }
        return displayValue;
    }
    const getConnectionDescription = (connectionId) => {
        let connection = systemConnectionList.find(item => item.connectionId === connectionId);
        if (connection) {
            return connection.description;
        }
        return "";
    }
    return (
        <FullHeightContainerLayout
            showHeader={false}
            showFooter={true}
            content={
                <Table size="small"
                    dataSource={systemConnections}
                    pagination={false}
                    scroll={{ y: "100vh" }}
                    className="form-table container-height-100">
                    <Column
                        width="5%"
                        title="No."
                        key="index"
                        render={(value, field, index) => index + 1}
                    />
                    <Column
                        title={"Name"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "connectionId"]}
                                    {...getFormErrors(`systemConnections.${field.name}.connectionId`)}>
                                    <Select
                                        showSearch
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={value => onSystemConnectionChange(value, field)}
                                        {...(() => (readOnly ? { open: false } : {}))()}>
                                        {systemConnectionList.map(connection => (<Option key={connection.connectionId} value={connection.connectionId}>{connection.name}</Option>))}
                                    </Select>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        title={"Description"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}>
                                    <>{formRef.current.getFieldValue(["systemConnections", field.name, "description"]) || getConnectionDescription(formRef.current.getFieldValue(["systemConnections", field.name, "connectionId"]))}</>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        title={"Type"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}>
                                    <>{getConnectionTypeLabel(formRef.current.getFieldValue(["systemConnections", field.name, "type"]))}</>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="10%"
                        title={"Active"}
                        render={(value, field, index) => {
                            return (
                                <Form.Item
                                    {...field}
                                    name={[field.name, "active"]}
                                    valuePropName="checked"
                                    initialValue={true}>
                                    <Checkbox disabled={readOnly}></Checkbox>
                                </Form.Item>
                            );
                        }}
                    />
                    <Column
                        width="5%"
                        render={(value, field, index) => {
                            return (
                                <Icon
                                    color='blue'
                                    name='trash alternate'
                                    className="action-cursor"
                                    onClick={() => {
                                        onDeleteSystemConnection(field, () => remove(field.name));
                                    }}
                                    disabled={readOnly} />
                            );
                        }}
                    />
                </Table>
            }
            footer={
                <Form.Item>
                    <Button
                        block
                        onClick={() => {
                            setScrollToLastRow(true);
                            add();
                        }}
                        disabled={readOnly}>
                        <PlusOutlined />
                        {systemConnections.length === 0 ? "Add system connection" : "Add another system connection"}
                    </Button>
                </Form.Item>
            }>
        </FullHeightContainerLayout>
    );
}

export default BusinessAreaSystemConnectionsList;