
import { put, call, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import { Auth } from "aws-amplify";
import errorHandler from 'common/errorHandler';

function* getJdbcConnections(api) {
    let result = yield call([api, api.get], `${Endpoints.systemConnections}/api/v1/jdbc`);
    return result.data;
}

function* createJdbcConnection(api, connection) {
    let result = yield call([api, api.post], `${Endpoints.systemConnections}/api/v1/jdbc`, connection);
    return result.data;
}

function* getJdbcConnection(api, connectionId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.systemConnections}/api/v1/jdbc/item/${connectionId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* updateJdbcConnection(api, connectionId, updatedJdbcConnection) {
    let response = yield call([api, api.put], `${Endpoints.systemConnections}/api/v1/jdbc/item/${connectionId}`, updatedJdbcConnection);
    return response.data;
}

function* deleteJdbcConnection(api, connectionId) {
    yield call([api, api.delete], `${Endpoints.systemConnections}/api/v1/jdbc/item/${connectionId}`);
}

export function* getJdbcConnectionsRequest(api) {
    try {
        const result = yield call(getJdbcConnections, api);
        yield put(actions.getJdbcConnectionsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "connections");
        yield put(actions.getJdbcConnectionsFailure(errorObject));
    }
}

export function* createJdbcConnectionsRequest(api, { connection }) {
    try {
        let createdJdbcConnection = yield call(createJdbcConnection, api, connection);
        yield put(actions.createJdbcConnectionSuccess(createdJdbcConnection));
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "connection");
        yield put(actions.createJdbcConnectionFailure(errorObject));
    }
}

export function* getJdbcConnectionRequest(api, { connectionId }) {
    try {
        const connection = yield call(getJdbcConnection, api, connectionId);
        yield put(actions.getJdbcConnectionSuccess(connection));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "app client");
        yield put(actions.getJdbcConnectionFailure(errorObject));
    }
}

export function* updateJdbcConnectionRequest(api, { connectionId, updatedJdbcConnection }) {
    try {
        const connection = yield call(updateJdbcConnection, api, connectionId, updatedJdbcConnection);
        yield put(actions.updateJdbcConnectionSuccess(connection));
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "connection");
        yield put(actions.updateJdbcConnectionFailure(errorObject));
    }
}

export function* deleteJdbcConnectionRequest(api, { connectionId }) {
    try {
        yield call(deleteJdbcConnection, api, connectionId);
        yield put(actions.deleteJdbcConnectionSuccess(connectionId));
    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "connection");
        yield put(actions.deleteJdbcConnectionFailure(errorObject));
    }
}

export function* watchGetJdbcConnectionsRequest(api, params) {
    yield call(getJdbcConnectionsRequest, api);
}

export function* watchCreateJdbcConnectionRequest(api, { params }) {
    yield call(createJdbcConnectionsRequest, api, params);
}

export function* watchGetJdbcConnectionRequest(api, { params }) {
    yield call(getJdbcConnectionRequest, api, params);
}

export function* watchUpdateJdbcConnectionRequest(api, { params }) {
    yield call(updateJdbcConnectionRequest, api, params);
}

export function* watchDeleteJdbcConnectionRequest(api, { params }) {
    yield call(deleteJdbcConnectionRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_JDBC_CONNECTIONS_REQUEST, watchGetJdbcConnectionsRequest, api);
    yield takeEvery(actions.CREATE_JDBC_CONNECTION_REQUEST, watchCreateJdbcConnectionRequest, api);
    yield takeLatest(actions.GET_JDBC_CONNECTION_REQUEST, watchGetJdbcConnectionRequest, api);
    yield takeLatest(actions.UPDATE_JDBC_CONNECTION_REQUEST, watchUpdateJdbcConnectionRequest, api);
    yield takeLatest(actions.DELETE_JDBC_CONNECTION_REQUEST, watchDeleteJdbcConnectionRequest, api);
}
