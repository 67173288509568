import { DeleteFilled } from "@ant-design/icons";
import FormulaPresenter from "./FormulaPresenter";

type FormulaBuilderProps = {
    builder?: IFormulaBuilder;
    sourceColumns?: string[];
    onSelect: (selected: IFormulaFunction, parentId?: string) => void;
    onReset: () => void;
};

export default function FormulaBuilder({
    builder,
    sourceColumns = [],
    onSelect,
    onReset,
}: FormulaBuilderProps) {
    return (
        <div className="flex flex-row justify-between rounded-sm bg-black/5 min-h-8">
            <div className="flex flex-row flex-1 items-stretch px-1 py-[2px] overflow-hidden *:flex *:items-center text-sm min-w-80">
                <FormulaPresenter
                    builder={builder}
                    sourceColumns={sourceColumns}
                    onSelect={onSelect}
                />
            </div>
            {builder && (
                <button
                    className="flex justify-center items-center bg-red-400 hover:bg-red-500 text-white rounded-r-sm w-8"
                    onClick={onReset}
                >
                    <DeleteFilled style={{ fontSize: 12 }} />
                </button>
            )}
        </div>
    );
}
