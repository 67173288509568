import React, { useRef } from 'react';
import { Layout, Space, Button, Table, Empty, Row, Col } from "antd";
import { PlusOutlined, EditOutlined, MinusOutlined } from "@ant-design/icons";
import { useComponentDidUpdateEffect } from 'common/customHooks';

const { Content, Footer, Header } = Layout;

/** @type {import('./types').FullHeightContainerLayout} */
function FullHeightContainerLayout({ content, footer, header, showHeader = false, showFooter = true, contentStyle = {}, headerStyle = {}, footerStyle = {}, scrollContentToBottom, ...props }) {

    const contentElementRef = useRef(null);

    useComponentDidUpdateEffect(() => {
        if (scrollContentToBottom) {
            if (contentElementRef.current) {
                contentElementRef.current.scrollIntoView(false);
            }
        }
    }, [scrollContentToBottom]);

    return <Layout className='full-height-container-layout' {...props}>
        {
            showHeader && header
                ?
                <Header style={{ height: "auto", padding: "unset", color: "unset", background: "unset", ...headerStyle }}>
                    {header}
                </Header>
                :
                <></>
        }
        <Content ref={contentElementRef} style={contentStyle}>
            {content}
        </Content>
        {
            showFooter && footer
                ?
                <Footer style={footerStyle}>
                    {footer}
                </Footer>
                :
                <></>
        }
    </Layout>
}

export default FullHeightContainerLayout