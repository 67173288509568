import React, { useState, useRef } from "react";
import { Form, Input, Modal, Table, Button, Tooltip } from "antd";
import { PlusOutlined, WarningTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout } from 'components';

const { Column } = Table;
const StaticPickListModal = ({ formRef, fields, fieldErrors, readOnly, visible, maxLength, onAddRow, onRemoveRow, onCreate, onCancel }) => {
    const [scrollToLastRow, setScrollToLastRow] = useState(false);
    const codeInputRef = useRef(null);

    return (
        <Modal
            visible={visible}
            bodyStyle={{ height: "50vh" }}
            centered={true}
            title="Static Picklist"
            okText={formRef ? "Save" : "Ok"}
            cancelText="Cancel"
            onCancel={() => {
                onCancel();
            }}
            onOk={() => {
                if (onCreate) {
                    onCreate();
                }
                else {
                    onCancel();
                }
            }}
            width={800}>
            <FullHeightContainerLayout
                content={
                    <Table size="small"
                        dataSource={fields}
                        pagination={false}
                        className="form-table">
                        <Column
                            width="10%"
                            title={"No."}
                            key="index"
                            render={(value, field, index) => {
                                return <>{index + 1}</>
                            }}
                        />
                        <Column
                            width="30%"
                            title={"Code"}
                            dataIndex={"code"}
                            render={(value, field, index) => {
                                return (
                                    formRef ?
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "code"]}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[{ required: true, message: 'Code cannot be empty' }]}>
                                            <Input readOnly={readOnly} ref={element => {
                                                if (element && element.input && scrollToLastRow && index === fields.length - 1) {
                                                    if (codeInputRef.current !== element.input) {
                                                        scrollIntoView(element.input);
                                                        element.input.focus();
                                                        codeInputRef.current = element.input;
                                                    }
                                                }
                                            }} />
                                        </Form.Item>
                                        :
                                        <>{value}</>
                                );
                            }}
                        />
                        <Column
                            title={"Description"}
                            dataIndex={"description"}
                            render={(value, field, index) => {
                                return (
                                    formRef ?
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "description"]}>
                                            <Input readOnly={readOnly}></Input>
                                        </Form.Item>
                                        :
                                        <>{value}</>
                                );
                            }}
                        />
                        {onRemoveRow && <Column
                            width="5%"
                            render={(value, field, index) => {
                                return (
                                    <Icon
                                        color='blue'
                                        name='trash alternate'
                                        className="action-cursor"
                                        onClick={() => {
                                            onRemoveRow(field.name);
                                        }}
                                        disabled={readOnly} />
                                );
                            }}
                        />
                        }
                    </Table>
                }
                footer={
                    <Form.Item>
                        <Button
                            block
                            onClick={() => {
                                setScrollToLastRow(true);
                                onAddRow();
                            }} disabled={readOnly || fields.length >= maxLength}>
                            <PlusOutlined />
                            {fields.length === 0 ? "Add item" : "Add another item"}
                        </Button>
                        {
                            fieldErrors && fieldErrors.length > 0 ?
                                <Form.Item>
                                    <Form.ErrorList errors={fieldErrors} />
                                </Form.Item>
                                :
                                <></>
                        }

                    </Form.Item>
                }
                showFooter={onAddRow ? true : false}>
            </FullHeightContainerLayout>
        </Modal >
    );
};

export default StaticPickListModal;