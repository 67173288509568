import React, { useState } from "react";
import { Row, Col, Button, Image, Typography, Space } from "antd";

const { Title } = Typography;

const SystemConnectionItems = ({ onItemClick }) => {

    return <Row style={{ width: "100%", height: "50%" }} gutter={[16, 16]}>
        <Col span={24} style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
            <Row justify="space-evenly">
                <Col span={7}>
                    <Button style={{ width: "100%", height: "100%" }} onClick={e => onItemClick("aws_s3")}>
                        <Space direction="vertical" size={"small"}>
                            <Image width={60} height={60} src="/icons/system-connections/amazon-s3-storage.png"></Image>
                            <Title level={4} ellipsis={true}>Amazon S3</Title>
                        </Space>
                    </Button>
                </Col>
                <Col span={7}>
                    <Button disabled={true} style={{ width: "100%", height: "100%" }}>
                        <Space direction="vertical" size={"small"}>
                            <Image width={60} height={60} src="/icons/system-connections/hubspot.png"></Image>
                            <Title level={4} ellipsis={true}>HubSpot</Title>
                        </Space>
                    </Button>
                </Col>
                <Col span={7}>
                    <Button disabled={true} style={{ width: "100%", height: "100%" }}>
                        <Space direction="vertical" size={"small"}>
                            <Image width={60} height={60} src="/icons/system-connections/azure.png"></Image>
                            <Title level={4} ellipsis={true}>Azure Storage</Title>
                        </Space>
                    </Button>
                </Col>
            </Row>
            <Row justify="space-evenly">
                <Col span={7}>
                    <Button disabled={true} style={{ width: "100%", height: "100%" }}>
                        <Space direction="vertical" size={"small"}>
                            <Image width={60} height={60} src="/icons/system-connections/salesforce.png"></Image>
                            <Title level={4} ellipsis={true}>Salesforce</Title>
                        </Space>
                    </Button>
                </Col>
                <Col span={7}>
                    <Button disabled={true} style={{ width: "100%", height: "100%" }}>
                        <Space direction="vertical" size={"small"}>
                            <Image src="/icons/system-connections/service_now.png"></Image>
                            <Title level={4} ellipsis={true}>Servicenow</Title>
                        </Space>
                    </Button>
                </Col>
                <Col span={7}>
                    <Button disabled={true} style={{ width: "100%", height: "100%" }}>
                        <Space direction="vertical" size={"small"}>
                            <Image width={60} height={60} src="/icons/system-connections/ms-dynamics.png"></Image>
                            <Title level={4} ellipsis={true}>Microsoft Dynamics 365</Title>
                        </Space>
                    </Button>
                </Col>
            </Row>
            <Row justify="space-evenly">
                <Col span={7}>
                    <Button style={{ width: "100%", height: "100%" }} onClick={e => onItemClick("jdbc")}>
                        <Space direction="vertical" size={"small"}>
                            <Image width={60} height={60} src="/icons/system-connections/postgresql.png"></Image>
                            <Title level={4} ellipsis={true}>PostgreSQL</Title>
                        </Space>
                    </Button>
                </Col>
                <Col span={7}>
                </Col>
                <Col span={7}>
                </Col>
            </Row>
        </Col>
    </Row>
};

export default SystemConnectionItems;