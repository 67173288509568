import React, { useState, useEffect } from 'react';
import { Form, Space, Button, Table, Pagination, Row, Col, Switch, Tooltip } from "antd";
import { PlusOutlined, EditOutlined, MinusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { ProhibitedArea, FullHeightContainerLayout } from "components";
import CypressTestIds from 'cypress/CypressTestIds';

const { Column } = Table;

const RoleList = ({ permission, roleList, onEditRole, onViewRole, onViewRoleHistory, onActivateDeactivateRole, onDeleteRole, onAddRole, onPageSelect }) => {
    const [pagination, setPagination] = useState({ defaultPageSize: 100, pageSize: 100, page: 1 })
    const [paginatedRoleList, setPaginatedRoleList] = useState(roleList && roleList.slice(pagination.page - 1, pagination.pageSize));

    useEffect(() => {
        setPaginatedRoleList(roleList && roleList.slice((pagination.page - 1) * pagination.pageSize, (pagination.page * pagination.pageSize)));
    }, [roleList]);

    const onPageClick = (page, pageSize) => {
        let currentPagination = { ...pagination };
        let currentPaginatedRoleList = roleList && roleList.slice((page - 1) * pageSize, (page * pageSize));
        setPaginatedRoleList(currentPaginatedRoleList);
        currentPagination.page = page;
        currentPagination.pageSize = pageSize;
        setPagination(currentPagination);
    }

    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }
    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <FullHeightContainerLayout
                    showHeader={false}
                    showFooter={true}
                    content={
                        <FullHeightContainerLayout
                            showHeader={false}
                            content={
                                <Table size="small"
                                    rowKey="roleId"
                                    dataSource={paginatedRoleList}
                                    scroll={{ y: "100vh" }}
                                    className="container-height-100"
                                    pagination={false}
                                    data-testid={CypressTestIds.ROLES_LIST_TABLE}>
                                    <Column
                                        width="6em"
                                        render={(value, role, index) => <>
                                        <Tooltip title ='Edit Role'>
                                            <Icon
                                                disabled={role.type === "SYSTEM" || !permission.canEdit}
                                                color='blue'
                                                name='pencil'
                                                className="action-cursor"
                                                onClick={() => onEditRole(role.roleId)} 
                                                data-testid={CypressTestIds.ROLES_LIST_EDIT_BUTTON}/> </Tooltip>
                                                <Tooltip title ='View Role'>                                       
                                                 <Icon
                                                disabled={!permission.canView}
                                                color='blue'
                                                name='eye'
                                                className="action-cursor"
                                                onClick={() => onViewRole(role.roleId)} 
                                                data-testid={CypressTestIds.ROLES_LIST_VIEW_BUTTON}/> </Tooltip>     
                                                <Tooltip title ='View Role History'> 
                                            <Icon
                                                disabled={!permission.canView}
                                                color='blue'
                                                name='history'
                                                className="action-cursor"
                                                onClick={() => onViewRoleHistory(role.roleId)} 
                                                data-testid={CypressTestIds.ROLES_LIST_HISTORY_BUTTON}/> </Tooltip>
                                        </>}
                                    />
                                    <Column
                                         width="15%"
                                        dataIndex={"name"}
                                        title={"Role"}
                                        render={(value, role, index) => <>{value}</>}
                                    />
                                    <Column
                                        width="10%"
                                        dataIndex={"permissionsCount"}
                                        title={"Permissions"}
                                        render={(value, role, index) => <>{value || 0}</>}
                                    />
                                    <Column
                                        width="10%"
                                        dataIndex={"usersCount"}
                                        title={"Users"}
                                        render={(value, role, index) => <>{value || 0}</>}
                                    />
                                    <Column
                                        width="10%"
                                        dataIndex={"appClientCount"}
                                        title={"Credentials"}
                                        render={(value, role, index) => <>{value || 0}</>}
                                    />
                                    <Column
                                        width="10%"
                                        dataIndex="active"
                                        title={"Active"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, role, index) => {
                                            return <Switch
                                                checked={value}
                                                disabled={role.type === "SYSTEM" || !permission.canEdit}
                                                onClick={(checked, event) => onActivateDeactivateRole(role.roleId, role.version, checked)}
                                                data-testid={CypressTestIds.ROLES_LIST_ACTIVE_SWITCH}>
                                            </Switch>;
                                        }}
                                    />
                                    <Column
                                        width="5%"
                                        render={(value, role, index) => {
                                            return (
                                                 <Tooltip title ='Delete Role'> 
                                                <Icon
                                                    disabled={role.type === "SYSTEM" || !permission.canDelete}
                                                    color='blue'
                                                    name='trash alternate'
                                                    className="action-cursor"
                                                    onClick={() => onDeleteRole(role)} 
                                                    data-testid={CypressTestIds.ROLES_LIST_DELETE_BUTTON}/> </Tooltip> 
                                            );
                                        }}
                                    />
                                </Table>
                            }
                            showFooter={true}
                            footer={
                                <Row justify="end" style={{ padding: "0.2rem 0rem" }}>
                                    <Col span={24} style={{ textAlign: "end" }}>
                                        <Pagination
                                            size="small"
                                            hideOnSinglePage={true}
                                            total={roleList.length}
                                            defaultPageSize={pagination.defaultPageSize}
                                            pageSize={pagination.pageSize}
                                            responsive={true}
                                            showSizeChanger={false}
                                            onChange={onPageClick} />
                                    </Col>
                                </Row>
                            }>
                        </FullHeightContainerLayout>
                    }
                    footer={
                        <Row className="table-footer-row">
                            <Col span={24} className="footer-right-column">
                                <Button
                                    disabled={!permission.canAdd}
                                    type="primary"
                                    onClick={onAddRole}
                                    data-testid={CypressTestIds.ROLES_LIST_ADD_ROLE_BUTTON}>
                                    <PlusOutlined />  Add Role
                                </Button>
                            </Col>
                        </Row>
                    }
                    footerStyle={{ paddingTop: "1rem" }}>

                </FullHeightContainerLayout>
            </Col>
        </Row>
    );
}

export default RoleList;