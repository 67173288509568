import { Handle, Node, NodeProps } from "@xyflow/react";
import { useAppSelector } from "hooks";
import React, { memo } from "react";

export type SourceNodeType = Node<
    {
        label: string;
        column: string;
    },
    "sourceNode"
>;

function SourceNode({
    data,
    sourcePosition,
    targetPosition,
    isConnectable,
}: NodeProps<SourceNodeType>) {
    const { data: sampleData, row } = useAppSelector(
        (state) => state.dataMapperFlow,
    );

    return (
        <>
            {isConnectable && targetPosition && (
                <Handle
                    type="target"
                    position={targetPosition}
                    style={handleStyle}
                />
            )}
            <div className="flex flex-col justify-center items-center gap-1 p-3 bg-cyan-100 rounded-lg rounded-tr-none min-w-40 min-h-16">
                <span className="text-sm">{data.label}</span>
                <span className="text-[0.8em] text-gray-500">
                    ({sampleData && sampleData[row][data.column]})
                </span>
                <div className="absolute -top-2 right-0 text-[0.6em] bg-cyan-200 px-2 py-[0.3em] rounded-bl-lg rounded-tr-lg rounded-tl-sm">
                    source
                </div>
            </div>
            {isConnectable && sourcePosition && (
                <Handle
                    type="source"
                    position={sourcePosition}
                    style={handleStyle}
                />
            )}
        </>
    );
}

const handleStyle: React.CSSProperties = {
    backgroundColor: "#0891b2",
    height: "0.8rem",
    width: "0.8rem",
};

export default memo(SourceNode);
