import React, { useState } from "react";
import { Button, Table, Checkbox, Row, Col, Tooltip, List, Typography } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { RolePermissionHistoryList, RoleChangedAttributes, RoleUserHistoryList, FullHeightContainerLayout } from "components";
import _ from 'lodash';
import CypressTestIds from 'cypress/CypressTestIds';

const { Column } = Table;
const { Title, Text, Link } = Typography;

const RecordDetail = ({ recordType, recordId, record, ...props }) => {
    switch (recordType) {
        case "ROLE":
            return <RoleChangedAttributes key={recordId} record={record}></RoleChangedAttributes>
        case "ROLE_PERMISSIONS":
            return <FullHeightContainerLayout
                showHeader={true}
                showFooter={false}
                header={<Title level={5}><Text>Role Permissions</Text></Title>}
                content={<RolePermissionHistoryList key={recordId} rolePermissionRevisions={record.data}></RolePermissionHistoryList>}>
            </FullHeightContainerLayout>
        case "ROLE_USERS":
            return (
                <FullHeightContainerLayout
                    showHeader={true}
                    showFooter={false}
                    header={<Title level={5}><Text>Role Users</Text></Title>}
                    content={<RoleUserHistoryList key={recordId} roleUserRevisions={record.data}></RoleUserHistoryList>}>
                </FullHeightContainerLayout>
            )
        default:
            return <></>
    }
}


const RoleHistoryList = ({ roleRevisions }) => {
    const [recordDetail, setRecordDetail] = useState(null);
    const [containerHeight, setContainerHeight] = useState("100%");
    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                    <Col span={24}>
                        <FullHeightContainerLayout
                            content={<Table size="small"
                                rowKey="data.version"
                                dataSource={roleRevisions}
                                pagination={false}
                                scroll={{ y: `100vh` }}
                                className={"container-height-100"}
                                data-testid={CypressTestIds.COMMON_OBJECTS_REVISIONS_LIST_TABLE}>
                                <Column
                                    width="3rem"
                                    title="No."
                                    key="index"
                                    render={(value, item, index) => index + 1}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={["data", "name"]}
                                    title={"Name"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={["data", "active"]}
                                    title={"Active"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, revision, index) => <Tooltip placement="topLeft" title={value !== null && value !== undefined && String(value)}>{value !== null && value !== undefined && String(value)}</Tooltip>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "createdByEmail"]}
                                    title={"Created By"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={["data", "createdDate"]}
                                    title={"Created Date"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{moment.utc(value).toDate().toLocaleString()}</Tooltip>}
                                />
                                <Column
                                    width="15rem"
                                    dataIndex={["data", "updatedByEmail"]}
                                    title={"Updated By"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={["data", "updatedDate"]}
                                    title={"Updated Date"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, revision, index) => <Tooltip placement="topLeft" title={value}>{moment.utc(value).toDate().toLocaleString()}</Tooltip>}
                                />
                                <Column
                                    width="10rem"
                                    dataIndex={["data", "permissions"]}
                                    title={"Permissions"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, revision, index) => <Tooltip placement="topLeft" title={`${value.length} permission(s) changed`}>
                                        {value.length > 0
                                            ?
                                            <Link onClick={() => {
                                                setContainerHeight("50%");
                                                setRecordDetail({ type: "ROLE_PERMISSIONS", recordId: revision.data.roleId, data: value });
                                            }}>
                                                {`${value.length}`}
                                            </Link>
                                            :
                                            <>{`${value.length}`}</>}
                                    </Tooltip>}
                                />
                                <Column
                                    width="10rem"
                                    dataIndex={["data", "users"]}
                                    title={"Users"}
                                    ellipsis={{
                                        showTitle: false,
                                    }}
                                    render={(value, revision, index) => <Tooltip placement="topLeft" title={`${value.length} user(s) changed`}>
                                        {value.length > 0
                                            ?
                                            <Link onClick={() => {
                                                setContainerHeight("50%");
                                                setRecordDetail({ type: "ROLE_USERS", recordId: revision.data.roleId, data: value });
                                            }}>
                                                {`${value.length}`}
                                            </Link>
                                            :
                                            <>{`${value.length}`}</>}
                                    </Tooltip>}
                                />
                                <Column
                                    width="12rem"
                                    dataIndex={"changes"}
                                    title={"Changes"}
                                    render={(value, revision, index) => {
                                        if (value) {
                                            return <>
                                                {Object.keys(value).reduce((items, key) => {
                                                    if (Object.keys(value[key]).length > 0) {
                                                        items.push(<Row key={key}>
                                                            <Col>
                                                                <Link onClick={() => {
                                                                    setContainerHeight("50%");
                                                                    setRecordDetail({
                                                                        type: "ROLE",
                                                                        name: `${_.capitalize(key)} attributes`,
                                                                        recordId: revision.data.dataDomainId,
                                                                        data: value[key],
                                                                        previousData: revision.previousChanges && revision.previousChanges[key]
                                                                    });
                                                                }}>
                                                                    {`${key} attributes`}
                                                                </Link>
                                                            </Col>
                                                        </Row>);
                                                    }
                                                    return items;
                                                }, [])}
                                            </>
                                        }
                                        return <></>;
                                    }}
                                />
                            </Table>}>
                        </FullHeightContainerLayout>
                    </Col>
                </Row>
                {
                    recordDetail
                        ?
                        <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                            <Col span={24}>
                                <FullHeightContainerLayout
                                    showHeader={false}
                                    content={<RecordDetail recordType={recordDetail.type} recordId={recordDetail.recordId} record={recordDetail}></RecordDetail>}
                                    showFooter={false}>
                                </FullHeightContainerLayout>
                            </Col>
                        </Row>
                        :
                        <></>
                }
            </Col>
        </Row>
    );
};

export default RoleHistoryList;