import { Button, Input, Modal, Select, Table, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import FormulaParser from "../FormulaParser";

const { Column } = Table;
const { Option } = Select;

type ExpressionInputType = {
    isVisible: boolean;
    targetField: IMappingDestinationField | null;
    targetFieldIndex: number;
    variables?: IFormulaVariable[];
    expression?: string;
};

type DataMapperProps = {
    destinationFields: IMappingDestinationField[];
    sourceFields: string[];
    sourceData: IFormulaParserDataInput[];
    onMappingChanged: (fields: IMappingDestinationField[]) => void;
};

const DataMapper = ({
    destinationFields,
    sourceFields,
    sourceData,
    onMappingChanged,
}: DataMapperProps) => {
    const [fields, setFields] =
        useState<IMappingDestinationField[]>(destinationFields);
    const [expressionInput, setExpressionInput] = useState<ExpressionInputType>(
        { isVisible: false, targetField: null, targetFieldIndex: -1 },
    );
    const formulaParserRef = useRef<IEvaluateExpressionHandle>(null);

    useEffect(() => {
        setFields(destinationFields);
    }, [destinationFields]);

    const onMappingTypeChange = (value: string, fieldIndex: number) => {
        const newFields = [...fields];
        newFields[fieldIndex] = { ...newFields[fieldIndex] };
        newFields[fieldIndex].mappingType = value;
        newFields[fieldIndex].value = "";
        newFields[fieldIndex].variables = [];
        setFields(newFields);
        onMappingChanged && onMappingChanged(newFields);
    };

    const onDataColumnChange = (value: string, fieldIndex: number) => {
        const newFields = [...fields];
        newFields[fieldIndex] = { ...newFields[fieldIndex] };
        newFields[fieldIndex].value = value;
        setFields(newFields);
        onMappingChanged && onMappingChanged(newFields);
    };

    const onExpressionModelOpenClick = (targetFieldIndex: number) => {
        const newExpressionInput = { ...expressionInput };
        newExpressionInput.targetFieldIndex = targetFieldIndex;
        newExpressionInput.isVisible = true;
        newExpressionInput.variables = fields[targetFieldIndex].variables || [];
        newExpressionInput.expression = fields[targetFieldIndex].value || "";
        setExpressionInput(newExpressionInput);
    };

    const onExpressionModelOkClick = () => {
        const evaluationResult = formulaParserRef.current?.evaluateExpression();
        if (evaluationResult && !evaluationResult.error) {
            const newExpressionInput = { ...expressionInput };
            newExpressionInput.targetFieldIndex = -1;
            newExpressionInput.isVisible = false;

            const newFields = [...fields];
            newFields[expressionInput.targetFieldIndex] = {
                ...newFields[expressionInput.targetFieldIndex],
            };
            newFields[expressionInput.targetFieldIndex].value =
                evaluationResult.formulaExpression ?? "";
            newFields[expressionInput.targetFieldIndex].variables =
                evaluationResult.variables ?? Array<IFormulaVariable>();
            setFields(newFields);
            onMappingChanged && onMappingChanged(newFields);
            setExpressionInput(newExpressionInput);
        }
    };

    const onExpressionModelCancelClick = () => {
        const newExpressionInput = { ...expressionInput };
        newExpressionInput.targetFieldIndex = -1;
        newExpressionInput.isVisible = false;
        setExpressionInput(newExpressionInput);
    };

    const sourceFieldOptions = React.useMemo(() => {
        const options: { label: string; value: string }[] = [];
        [...sourceFields]
            .sort()
            .filter((a, i) => {
                // Remove duplicates
                if (i === 0) {
                    return true;
                }

                return a !== sourceFields[i - 1];
            })
            .map((f) =>
                options.push({
                    value: f,
                    label: f,
                }),
            );

        return options;
    }, [sourceFields]);

    return (
        <>
            <Modal
                title="Formula Expression"
                width={"50%"}
                centered
                destroyOnClose={true}
                visible={expressionInput.isVisible}
                onOk={() => onExpressionModelOkClick()}
                onCancel={() => onExpressionModelCancelClick()}
                bodyStyle={{ height: "75vh", width: "100%" }}
            >
                <FormulaParser
                    ref={formulaParserRef}
                    inputColumns={sourceFields}
                    inputData={sourceData}
                    variables={expressionInput.variables}
                    expression={expressionInput.expression ?? ""}
                ></FormulaParser>
            </Modal>

            <Table<IMappingDestinationField>
                size="small"
                rowKey="fieldId"
                dataSource={fields}
                pagination={false}
                scroll={{ y: "100vh" }}
                className="container-height-100"
            >
                <Column<IMappingDestinationField>
                    width="15%"
                    dataIndex={"name"}
                    title={"Name"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, field, index) => (
                        <Tooltip placement="topLeft" title={value}>
                            {value}
                        </Tooltip>
                    )}
                />
                <Column<IMappingDestinationField>
                    width="10%"
                    dataIndex={"dataType"}
                    title={"Data Type"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, field, index) => (
                        <Tooltip placement="topLeft" title={value}>
                            {value}
                        </Tooltip>
                    )}
                />
                <Column<IMappingDestinationField>
                    width="10%"
                    dataIndex={"mappingType"}
                    title={"Mapping Type"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, field, index) => (
                        <Select
                            style={{ width: "100%" }}
                            defaultValue="DATA_COLUMN"
                            onChange={(value) =>
                                onMappingTypeChange(value, index)
                            }
                            value={field.mappingType}
                        >
                            <Option value="DATA_COLUMN">Data column</Option>
                            <Option value="FORMULA">Formula</Option>
                        </Select>
                    )}
                />
                <Column<IMappingDestinationField>
                    width="10%"
                    dataIndex={"value"}
                    title={"Mapped Value"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    shouldCellUpdate={(record, prevRecord) => {
                        if (record.mappingType !== prevRecord.mappingType) {
                            return true;
                        }
                        if (record.value !== prevRecord.value) {
                            return true;
                        }
                        return false;
                    }}
                    render={(value, field, index) =>
                        field.mappingType === "DATA_COLUMN" ? (
                            <Select
                                style={{ width: "100%" }}
                                showSearch
                                allowClear={true}
                                placeholder="Search to Select"
                                options={sourceFieldOptions}
                                optionFilterProp="label"
                                onChange={(value) =>
                                    onDataColumnChange(value, index)
                                }
                            />
                        ) : (
                            <Input
                                readOnly={true}
                                addonBefore="="
                                addonAfter={
                                    <Button
                                        size="small"
                                        type="link"
                                        onClick={() =>
                                            onExpressionModelOpenClick(index)
                                        }
                                    >
                                        fx
                                    </Button>
                                }
                                placeholder="SUM(amount,100)"
                                value={value}
                            />
                        )
                    }
                />
            </Table>
        </>
    );
};

export default DataMapper;
