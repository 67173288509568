import DefinitionsData from "./definitions.json";

export default function FormulaDictionary(): IFormulaDictionary {
    const definitions: IFormulaDictionary = new Map();

    DefinitionsData.forEach((dd) => {
        definitions.set(dd.name, {
            description: dd.description,
            syntax: dd.syntax ?? "",
        });
    });

    return definitions;
}
