import { Header } from "semantic-ui-react";

type ComingSoonProps = {
    prefix?: React.ReactNode;
};

export default function ComingSoon({ prefix }: ComingSoonProps) {
    return (
        <Header as="h4" color="blue">
            {prefix} Coming soon
        </Header>
    );
}
