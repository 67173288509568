import React from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PageWrapper(ComposedComponent) {
    return function (props) {
        return <>
            <ComposedComponent {...props} />
            <ToastContainer 
            pauseOnFocusLoss={false}/>
        </>
    }
}

export default composedComponent => PageWrapper(composedComponent);