import React, { useState, useEffect } from "react";
import { Form, Input, Button, Table, Checkbox, Select, Tooltip, Tag, Space, Row, Col } from "antd";
import { PlusOutlined, WarningTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import fieldDataTypes from 'common/data/fieldDataTypes';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout } from 'components';
import CypressTestIds from "cypress/CypressTestIds";

const { Column } = Table;
const { Option } = Select;

const ClassifiersTagField = ({ value, onChange }) => {
    const [classifiersTags, setClassifiersTags] = useState(value);

    useEffect(() => {
        setClassifiersTags(value);
    }, [value])

    return classifiersTags && classifiersTags.length > 0 ?
        <Row>
            {
                classifiersTags.slice(0, 2).map((tag, index) => <Col className="gutter-row" key={tag.tagId}><Tag key={tag.tagId}>{tag.tagName}</Tag></Col>)
            }
        </Row>
        :
        <></>
}

const SchemaModelFieldList = ({ formRef, fields, readOnly, add, remove, onFieldRowSelected, onDataTypeChange, getFormErrors, onFieldRowDelete, getFieldRowLevelError }) => {
    const [scrollToLastRow, setScrollToLastRow] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        if (scrollToLastRow === true) {
            setScrollToLastRow(false);
        }
    }, [scrollToLastRow]);

    const onClickRow = (record) => {
        return {
            onClick: () => {
                setSelectedRowKeys([record.key]);
                onFieldRowSelected && onFieldRowSelected(record);
            },
            onFocus: () => {
                setSelectedRowKeys([record.key]);
                onFieldRowSelected && onFieldRowSelected(record);
            },
        };
    }

    const onIsPrimaryChange = (e, field) => {
        if (e.target.checked) {
            let fields = formRef.current.getFieldValue(["fields"]);
            for (let i = 0; i < fields.length; i++) {
                if (i !== field.name) {
                    fields[i].isPrimaryKey = false;
                }
            }
            formRef.current.setFieldsValue({
                fields
            });
        }
    }

    const rowSelection = {
        selectedRowKeys,
        type: 'radio'
    };
    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <FullHeightContainerLayout
                    showHeader={false}
                    showFooter={add ? true : false}
                    content={<Table size="small"
                        data-testid={CypressTestIds.SCHEMA_FIELD_TABLE}
                        dataSource={fields}
                        pagination={false}
                        onRow={onClickRow}
                        rowSelection={rowSelection}
                        rowClassName={(field, index) => {
                            if (getFieldRowLevelError) {
                                let fieldError = getFieldRowLevelError(index);
                                if (fieldError) {
                                    return "error-row";
                                }
                            }
                            return null;
                        }}
                        className="form-table container-height-100"
                        scroll={{ y: "100vh" }}>
                        <Column
                            width="5%"
                            title={"No."}
                            key="index"
                            render={(value, field, index) => {
                                if (getFieldRowLevelError) {
                                    let fieldError = getFieldRowLevelError(index);
                                    return <>{fieldError ? <Tooltip placement="topLeft" title={Object.values(fieldError).join("/n")}><WarningTwoTone twoToneColor="#FF0000" /></Tooltip> : <></>} {index + 1}</>
                                }
                                return (index + 1)
                            }}
                        />
                        <Column
                            width="10%"
                            dataIndex={"isPrimaryKey"}
                            title={"Primary Key"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, field, index) => {
                                return (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, "isPrimaryKey"]}
                                        valuePropName="checked"
                                        initialValue={false}>
                                        <Checkbox disabled={readOnly} onChange={e => onIsPrimaryChange(e, field)}
                                        data-testid={CypressTestIds.SCHEMA_FIELD_ISPRIMARY_CHECKBOX}></Checkbox>
                                    </Form.Item>
                                );
                            }}
                        />
                        <Column
                            width="10%"
                            dataIndex={"isSortKey"}
                            title={"Sort Key"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, field, index) => {
                                return (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, "isSortKey"]}
                                        valuePropName="checked"
                                        initialValue={false}>
                                        <Checkbox disabled={readOnly}
                                        data-testid={CypressTestIds.SCHEMA_FIELD_ISSORTKEY_CHECKBOX}></Checkbox>
                                    </Form.Item>
                                );
                            }}
                        />
                        <Column
                            width="8%"
                            dataIndex={"active"}
                            title={"Active"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, field, index) => {
                                return (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, "active"]}
                                        valuePropName="checked"
                                        initialValue={true}>
                                        <Checkbox disabled={readOnly}
                                        data-testid={CypressTestIds.SCHEMA_FIELD_ISACTIVE_CHECKBOX}></Checkbox>
                                    </Form.Item>
                                );
                            }}
                        />
                        <Column
                            dataIndex={"classifiers"}
                            title={"Classifiers Tags"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, field, index) => {
                                return (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, "classifiers"]}
                                        {...getFormErrors(`fields.${field.name}.classifiers`)}>
                                        <ClassifiersTagField></ClassifiersTagField>
                                    </Form.Item>
                                );
                            }}
                        />
                        <Column
                            dataIndex={"name"}
                            title={"Field Name"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, field, index) => {
                                return (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, "name"]}
                                        validateFirst={true}
                                        rules={nameRules}
                                        {...getFormErrors(`fields.${field.name}.name`)}>
                                        <Input readOnly={readOnly} ref={element => (element && scrollToLastRow && index === fields.length - 1 && scrollIntoView(element.input))} 
                                        data-testid={CypressTestIds.SCHEMA_FIELD_NAME_INPUT} />
                                    </Form.Item>
                                );
                            }}
                        />
                        <Column
                            dataIndex={"description"}
                            title={"Field Description"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, field, index) => {
                                return (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, "description"]}
                                        rules={descriptionRules}
                                        {...getFormErrors(`fields.${field.name}.description`)}>
                                        <Input readOnly={readOnly}
                                        data-testid={CypressTestIds.SCHEMA_FIELD_DESCRIPTION_INPUT} ></Input>
                                    </Form.Item>
                                );
                            }}
                        />
                        <Column
                            width="15%"
                            dataIndex={"dataType"}
                            title={"Data Type"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, field, index) => {
                                return (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, "dataType"]}
                                        initialValue="string">
                                        <Select
                                            showSearch
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={e => onDataTypeChange && onDataTypeChange(e, field)}
                                            {...(() => (readOnly ? { open: false } : {}))()}
                                            data-testid={CypressTestIds.SCHEMA_FIELD_DATATYPE_SELECT}>
                                            {Object.keys(fieldDataTypes).sort().map(key => (<Option key={key} value={key}>{fieldDataTypes[key].name}</Option>))}  
                                        </Select>
                                    </Form.Item>
                                );
                            }}
                        />
                        <Column
                            width="10%"
                            dataIndex={"isRequired"}
                            title={"Required"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, field, index) => {
                                return (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, "isRequired"]}
                                        valuePropName="checked"
                                        initialValue={false}>
                                        <Checkbox disabled={readOnly}
                                        data-testid={CypressTestIds.SCHEMA_FIELD_ISREQUIRED_CHECKBOX}></Checkbox>
                                    </Form.Item>
                                );
                            }}
                        />
                        {remove && <Column
                            width="5%"
                            render={(value, field, index) => {
                                return (
                                    <Icon
                                        color='blue'
                                        name='trash alternate'
                                        className="action-cursor"
                                        onClick={() => {
                                            setSelectedRowKeys([]);
                                            onFieldRowDelete && onFieldRowDelete(() => remove(field.name));
                                        }}
                                        disabled={readOnly} 
                                        data-testid={CypressTestIds.SCHEMA_FIELD_DELETE_BUTTON}/>
                                );
                            }}
                        />
                        }
                        {/* <Column
                dataIndex={"validation"}
                title={"Validation"}
                render={(value, field, index) => {
                    return (
                        <Form.Item name={[index, "validation"]}>
                            <Checkbox></Checkbox>
                        </Form.Item>
                    );
                }}
            />
            <Column
                dataIndex={"classifiers"}
                title={"Classifiers"}
                render={(value, field, index) => {
                    return (
                        <Form.Item name={[index, "classifiers"]}>
                            <Checkbox></Checkbox>
                        </Form.Item>
                    );
                }}
            /> */}
                    </Table>
                    }
                    footer={<Form.Item>
                        <Button
                            block
                            onClick={() => {
                                setScrollToLastRow(true);
                                add();
                            }} disabled={readOnly}
                            data-testid={CypressTestIds.SCHEMA_FIELD_ADD_BUTTON}>
                            <PlusOutlined />
                            {fields.length === 0 ? "Add field" : "Add another field"}
                        </Button>
                    </Form.Item>}>
                </FullHeightContainerLayout>
            </Col>
        </Row>
    );
};

export default SchemaModelFieldList;